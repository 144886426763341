import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
// Icons
import GoogleIcon from "../../assets/icons/GoogleIcon";
//Google SignIn Api
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { googleLoginUser } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";

const GoogleSignIn = ({ isRegister }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => handleGoogleSignIn(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const handleGoogleSignIn = (response) => {
    let data = {
      access_token: response?.access_token,
    };
    dispatch(googleLoginUser(data, navigate));
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Divider
          style={{
            width: "20%",
            color: "white",
            borderColor: "white",
            margin: "0 10px",
          }}
        />
        <Typography
          variant="body4"
          color={"white"}
          sx={{ fontSize: "11px", fontWeight: "500", whiteSpace: "nowrap" }}
        >
          Or
        </Typography>
        <Divider
          style={{
            width: "20%",
            color: "white",
            borderColor: "white",
            margin: "0 10px",
          }}
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // flexDirection:'row',
            backgroundColor: "white",
            opacity: "0.8",
            borderRadius: "5px",
            height: "35px",
            width: "250px",
            marginTop: "10px",
            fontFamily: "inherit",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
          onClick={() => login()}
        >
          <GoogleIcon />
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              textTransform: "none",
              marginLeft: "10px",
              fontFamily: "inherit",
              color: "rgba(61, 61, 61, 0.70)",
            }}
          >
            Sign {isRegister ? "Up" : "In"} With Google
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default GoogleSignIn;
