import React from "react";

const OnboardingChevronRight = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_577_799)">
        <path
          d="M29.3333 9.16699C29.3333 10.5273 30.6772 12.5587 32.0375 14.2637C33.7865 16.4637 35.8765 18.3832 38.2727 19.848C40.0693 20.9462 42.2473 22.0003 44 22.0003M44 22.0003C42.2473 22.0003 40.0675 23.0545 38.2727 24.1527C35.8765 25.6193 33.7865 27.5388 32.0375 29.7352C30.6772 31.442 29.3333 33.477 29.3333 34.8337M44 22.0003L4.20741e-06 22.0003"
          stroke="white"
          strokeWidth="2.42"
        />
      </g>
      <defs>
        <clipPath id="clip0_577_799">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(44) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OnboardingChevronRight;
