import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import DesktopMenuOptionItem from "./DesktopMenuOptionItem";
import { useLocation, useNavigate } from "react-router-dom";
import EllipsisIcon from "../../assets/icons/headerIcons/EllipsisIcon";
import HomeIcon from "../../assets/icons/headerIcons/HomeIcon";
import TimelineIcon from "../../assets/icons/headerIcons/TimelineIcon";
import ExperiencesIcons from "../../assets/icons/headerIcons/ExperiencesIcons";
import OrdersIcon from "../../assets/icons/headerIcons/OrdersIcon";
import AboutUsIcon from "../../assets/icons/headerIcons/AboutUsIcon";
import SignInIcon from "../../assets/icons/SignInIcon";
import { logoutUser } from "../../redux/slices/authSlice";
import SignOutPinIcon from "../../assets/icons/SignOutPinIcon";
import ProfileSettingsModal from "../profile/ProfileSettingsModal";

const LeftDesktopMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { winery, access_token, user } = useSelector((state) => state.auth);
  const [errorImage, setErrorImage] = useState(false);
  const [activeOption, setActiveOption] = useState("home");
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [profileSettingsModal, setProfileSettingsModal] = useState(false);

  const handleClick = () => {
    if (!popoverVisible) {
      setPopoverVisible(true);
    } else {
      setPopoverVisible(false);
    }
  };
  const handleOptionClick = (option) => {
    setActiveOption(option);
    navigate(`/${option === "home" ? "" : option}`);
  };
  const handleProfileNavigate = () => {
    if (window.innerWidth < 768) {
      navigate("/profile");
    } else {
      setProfileSettingsModal(true);
    }
  };
  useEffect(() => {
    if (location?.pathname === "/") {
      setActiveOption("home");
    } else if (location?.pathname === "/timeline") {
      setActiveOption("timeline");
    } else if (
      location?.pathname === "/experiences" ||
      location?.pathname?.includes("experience")
    ) {
      setActiveOption("experiences");
    } else if (location?.pathname === "/all-orders") {
      setActiveOption("all-orders");
    } else if (location?.pathname === "/about-us") {
      setActiveOption("about-us");
    }
  }, [location]);

  return (
    <Box
      sx={{
        maxWidth: { sm: "33.333333%", md: "29.166667%", lg: "20.833333%" },
        height: "100vh",
        backgroundColor: "#F3F2EC",
        width: "100%",
        position: "fixed",
        overflowY: "auto",
        borderRight: "1px solid #3D3D3D40",
      }}
    >
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "column",
          position: "relative",
          minHeight: window.innerHeight < 600 ? "555px" : "95%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box sx={{ height: "auto" }}>
            <Box sx={{ marginBottom: "80px" }}>
              {!errorImage ? (
                <Box
                  sx={{
                    width: { xs: "100px", sm: "150px" },
                    height: "auto",
                    marginBottom: window?.innerHeight < 700 ? "10px" : "45px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={winery?.logo}
                    alt={winery?.company_name}
                    height={"auto"}
                    className="header-img"
                    onError={() => setErrorImage(true)}
                    style={{ color: "white", fontSize: "25px" }}
                  />
                </Box>
              ) : (
                <Box>
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontSize: "20px",
                      fontFamily: "inherit",
                      fontWeight: "600",
                      marginBottom: "30px",
                    }}
                  >
                    {winery?.company_name}
                  </Typography>{" "}
                </Box>
              )}
            </Box>
            <Box>
              {!winery?.hide_home && (
                <DesktopMenuOptionItem
                  icon={<HomeIcon isMenu />}
                  title="Home"
                  referenceRoute={["/", "post-details"]}
                  onClick={() => handleOptionClick("home")}
                />
              )}
              {!winery?.hide_timeline && (
                <DesktopMenuOptionItem
                  icon={<TimelineIcon isMenu />}
                  title="Timeline"
                  referenceRoute={["timeline"]}
                  onClick={() => handleOptionClick("timeline")}
                />
              )}
              <DesktopMenuOptionItem
                icon={<ExperiencesIcons isMenu />}
                title="Experiences"
                referenceRoute={["experiences", "experience-details"]}
                onClick={() => handleOptionClick("experiences")}
              />
              {access_token && (
                <DesktopMenuOptionItem
                  icon={<OrdersIcon isMenu />}
                  title="My Orders"
                  referenceRoute={[
                    "all-orders",
                    "order-details",
                    "order-success",
                  ]}
                  onClick={() => handleOptionClick("all-orders")}
                  itemWidth={"auto"}
                />
              )}
              <DesktopMenuOptionItem
                icon={
                  <AboutUsIcon
                    isMenu
                    activeDesktop={activeOption === "about-us"}
                  />
                }
                title="About us"
                referenceRoute={["about-us"]}
                onClick={() => handleOptionClick("about-us")}
                itemWidth={"auto"}
              />
              {!access_token && (
                <DesktopMenuOptionItem
                  icon={<SignInIcon width="27px" height="27px" />}
                  title="Sign In/Up"
                  onClick={() => navigate("/login")}
                />
              )}
            </Box>
          </Box>
          <Grid
            item
            sx={{
              fontSize: "11px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: 0,
              height: "auto",
              position: window.innerHeight > 700 && "absolute",
              bottom: "4%",
              left: 0,
              width: "100%",
            }}
          >
            <Grid>
              {access_token && (
                <Grid
                  container
                  sx={{
                    // marginTop: window?.innerHeight < 700 ? "0px" : "100px",
                    flexDirection: "column",

                    // position: "fixed",
                  }}
                >
                  {popoverVisible && (
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        width: "210px",
                        height: "42px",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "16px",
                        marginLeft: "20px",
                        marginTop: window?.innerHeight < 600 ? "5px" : "0px",
                      }}
                    >
                      <Button
                        onClick={() => dispatch(logoutUser(navigate))}
                        sx={{
                          backgroundColor: "#FFFFFF",
                          height: "30px",
                          width: "100%",
                          borderRadius: "10px",
                          "&:hover": {
                            backgroundColor: "#FFFFFF",
                            padding: 0,
                          },
                        }}
                      >
                        <Box sx={{ position: "absolute", marginTop: "50px" }}>
                          <SignOutPinIcon />
                        </Box>

                        <Typography
                          sx={{
                            fontFamily: "inherit",
                            fontSize: "14px",
                            color: "rgba(61, 61, 61, 0.90)",
                            fontWeight: "600",
                            textTransform: "none",
                          }}
                        >
                          Sign Out
                        </Typography>
                      </Button>
                    </Grid>
                  )}
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      marginLeft: "20px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        handleProfileNavigate();
                      }}
                      sx={{
                        fontFamily: "inherit",
                      }}
                    >
                      <Avatar
                        alt={user?.first_name}
                        src={
                          user?.profile_picture ? user?.profile_picture : "/"
                        }
                        sx={{ width: 37, height: 37, backgroundColor: "" }}
                      />

                      <Typography
                        sx={{
                          fontFamily: "inherit",
                          fontSize: "13px",
                          fontWeight: "700",
                          color: "rgba(61, 61, 61, 0.90)",
                          marginLeft: "10px",
                          maxWidth: { sm: "90px", md: "120px" },
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {user?.first_name} {user?.last_name}
                      </Typography>
                    </Button>
                    <Button
                      onClick={() => handleClick()}
                      sx={{ padding: 0, justifyContent: "flex-start" }}
                    >
                      <EllipsisIcon />
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {access_token && (
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  marginTop: "20px",
                  // marginLeft: "20px",
                }}
              >
                <Box sx={{ margin: "0px 5px 5px 10px" }}>Powered By </Box>
                <img
                  src={require("../../assets/images/winergrower-drawer-logo.png")}
                  alt=""
                  height={"auto"}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <ProfileSettingsModal
        profileSettingsModal={profileSettingsModal}
        setProfileSettingsModal={setProfileSettingsModal}
      />
    </Box>
  );
};

export default LeftDesktopMenu;
