import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import DOMPurify from "dompurify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
//Validation and Formik
import * as yup from "yup";
import { Form, Formik } from "formik";
//Components
import CommentComponent from "../comments/CommentComponent";
//Icons
import SendIcon from "../../assets/icons/SendIcon";
import LikeIcon from "../../assets/icons/LikeIcon";
import CommentIcon from "../../assets/icons/CommentIcon";
import { ChevronLeft } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  addPostComments,
  addPostReactions,
  fetchPostComments,
  fetchPostReactions,
  setExpandedBottomPrompt,
  setWatchedTimelineVideo,
} from "../../redux/slices/homeSlice";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import BottomSignInPrompt from "../layout/BottomSignInPrompt";
import PlayIcon from "../../assets/icons/PlayIcon";
//Video Player
import JWPlayer from "@jwplayer/jwplayer-react";

// Fancy Box
import ImageViewer from "react-simple-image-viewer";
import SharePopupComponent from "../sharePopup/SharePopupComponent";
import ShareIcon from "../../assets/icons/ShareIcon";

const PostDetailsPage = ({ postDetails }) => {
  const sanitizedHtml = DOMPurify.sanitize(postDetails?.description);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.auth);

  const { postComments, isCommentsLoading, postReactions } = useSelector(
    (state) => state.home
  );
  const [loaded, setLoaded] = useState(false);
  const [players, setPlayers] = useState({});
  const [isLiked, setIsLiked] = useState(false);
  const [reactionsCount, setReactionsCount] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [shareOptionsModal, setShareOptionsModal] = useState(false);

  const postDate = moment(
    postDetails?.created_at,
    "DD-MM-YYYY h:mm A"
  ).fromNow();

  const validationSchema = yup.object({
    postComment: yup
      .string()
      .required("Comment is required")
      .min(3, "Comment must be at least 3 characters long"),
  });

  const handleSharePost = (e) => {
    e.stopPropagation();

    setShareOptionsModal(true);
  };

  const handleSubmit = (values, { resetForm }) => {
    let obj = {
      slug: postDetails?.slug,
      text: values.postComment,
    };

    dispatch(addPostComments(obj, postDetails?.slug));
    resetForm();
  };

  const handleUpdateReaction = () => {
    if (access_token) {
      if (isLiked) {
        setReactionsCount(reactionsCount - 1);
      } else {
        setReactionsCount(reactionsCount + 1);
      }
      setIsLiked(!isLiked);
      let obj = {
        slug: postDetails?.slug,
      };
      dispatch(addPostReactions(obj, postDetails?.slug));
    } else {
      dispatch(setExpandedBottomPrompt(true));
    }
  };

  useEffect(() => {
    const loadPlayerLibrary = () => {
      const src = "https://cdn.jwplayer.com/libraries/mAzEIK3H.js";
      const script = document.createElement("script");
      script.src = src;
      script.type = "text/javascript";
      script.onload = () => setLoaded(true);
      document.body.append(script);
    };

    loadPlayerLibrary();
  }, []);

  const didMountCallback = ({ player, id }) => {
    setPlayers((prevPlayers) => ({ ...prevPlayers, [id]: player }));
    const eventLog = document.getElementById("log");

    player.on("all", (event) => {
      const li = document.createElement("li");
      li.innerText = `${id}: ${event}`;
      eventLog.prepend(li);
    });
  };

  const onBeforePlay = (event) => {
    Object.keys(players).forEach((playerId) => {
      const player = players[playerId];
      const isPlaying = player.getState() === "playing";
      if (isPlaying) {
        player?.pause();
      }
    });
  };

  const handleVideoEnded = () => {
    if (postDetails?.is_timeline_video) {
      console.log("Watched");
      dispatch(setWatchedTimelineVideo(true));
    }
  };
  useEffect(() => {
    dispatch(fetchPostComments(postDetails?.slug));
    dispatch(fetchPostReactions(postDetails?.slug));
  }, [postDetails?.slug]);

  useEffect(() => {
    if (postReactions) {
      setIsLiked(postReactions?.liked_by_user);
      setReactionsCount(postReactions?.count);
    }
  }, [postReactions]);
  const images = [postDetails?.image];
  if (showImage) {
    return (
      <>
        <ImageViewer
          src={images}
          disableScroll
          closeOnClickOutside={true}
          onClose={() => {
            setShowImage(0);
          }}
        />
      </>
    );
  }

  return (
    <Grid container>
      {window?.innerWidth < 768 && (
        <>
          <Grid
            item
            xs={12}
            onClick={() => navigate(-1)}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              cursor: "pointer",
              padding: "2px 10px",
              color: "#3D3D3D",
              fontSize: "13px",
              fontWeight: 500,
            }}
          >
            <ChevronLeft
              sx={{
                fontSize: "18px",
                marginLeft: "4px",
                marginRight: "5px",
                color: "#3D3D3D",
              }}
            />
            Back
          </Grid>
          <Divider
            sx={{
              backgroundColor: "#F7F7F7",
              width: "100%",
            }}
          />
        </>
      )}

      <Grid
        item
        xs={12}
        sx={{
          // height: !access_token && "72vh",
          paddingBottom: !access_token && "150px",
          overflowY: !access_token && "scroll",
        }}
      >
        <Grid
          container
          sx={{ padding: window.innerWidth < 768 ? "10px 20px" : "10px 60px" }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "inherit",
                fontSize: "16px",
                fontWeight: "600",
                color: "#3D3D3D",
              }}
            >
              {postDetails?.title?.charAt(0).toUpperCase() +
                postDetails?.title?.slice(1)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "inherit",
                fontSize: "11px",
                fontWeight: "500",
                color: "#3D3D3D",
                opacity: 0.8,
                margin: "5px 0px",
              }}
            >
              {postDate?.charAt(0).toUpperCase() + postDate?.slice(1)}
            </Typography>
          </Grid>

          {postDetails?.type === "image" && window?.innerWidth < 768 ? (
            <Grid
              sx={{
                width: "100%",
                height: "220px",
                borderRadius: "5px",
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 11.62%, rgba(0, 0, 0, 0.60) 22.95%, rgba(0, 0, 0, 0.00) 37.04%), url(${postDetails?.image}),  #D9D9D9`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "relative",
              }}
              onClick={() => {
                setShowImage(1);
              }}
            >
              <Grid
                container
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  bottom: 0,
                  position: "absolute",
                  padding: "0px 10px",
                  marginBottom: "10px",
                }}
              >
                <Grid
                  item
                  sx={{
                    height: "19px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: postDetails?.phase?.color,
                    borderRadius: "2px",
                    padding: "0px 5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "inherit",
                      fontSize: "12px",
                      fontWeight: "500",
                      color: postDetails?.phase?.text_color
                        ? postDetails?.phase?.text_color
                        : "rgba(61, 61, 61, 0.80)",
                    }}
                  >
                    {postDetails?.phase?.title?.charAt(0).toUpperCase() +
                      postDetails?.phase?.title?.slice(1)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : postDetails?.type === "image" ? (
            <Grid
              sx={{
                width: "100%",
                height: "250px",
                borderRadius: "5px",
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 11.62%, rgba(0, 0, 0, 0.60) 22.95%, rgba(0, 0, 0, 0.00) 37.04%), url(${postDetails?.image}),  #D9D9D9`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "relative",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowImage(1);
              }}
            >
              <Grid
                container
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  bottom: 0,
                  position: "absolute",
                  padding: "0px 10px",
                  marginBottom: "10px",
                }}
              >
                {postDetails?.type === "video" ? (
                  <Grid item sx={{ cursor: "pointer" }} xs={1}>
                    <PlayIcon />
                  </Grid>
                ) : (
                  <Grid />
                )}

                <Grid
                  item
                  sx={{
                    height: "19px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: postDetails?.phase?.color,
                    borderRadius: "2px",
                    padding: "0px 5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "inherit",
                      fontSize: "12px",
                      fontWeight: "500",
                      color: postDetails?.phase?.text_color
                        ? postDetails?.phase?.text_color
                        : "rgba(61, 61, 61, 0.80)",
                    }}
                  >
                    {postDetails?.phase?.title?.charAt(0).toUpperCase() +
                      postDetails?.phase?.title?.slice(1)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            postDetails?.type === "video" && (
              <Box sx={{ width: "100%", height: "100%", margin: "10px 0px" }}>
                {loaded ? (
                  <JWPlayer
                    config={{
                      width: "100%",
                      height: "100%",
                      content: {
                        display: "none",
                      },
                      displaytitle: false,
                      displaydescription: false,
                    }}
                    onBeforePlay={onBeforePlay}
                    didMountCallback={didMountCallback}
                    onComplete={handleVideoEnded}
                    playlist={`https://cdn.jwplayer.com/v2/media/${postDetails.media_id}`}
                  />
                ) : (
                  <>Error loading PLayer</>
                )}
              </Box>
            )
          )}

          {postDetails?.description && postDetails?.type !== "image" && (
            <Grid item xs={12}>
              <Typography
                dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                sx={{
                  color: "rgba(61, 61, 61, 0.80)",
                  fontFamily: "inherit",
                  fontWeight: "500",
                  fontSize: "13px",
                  "& p": {
                    margin: "0 !important",
                  },
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                <Grid container>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Button
                      onClick={() => handleUpdateReaction()}
                      sx={{
                        padding: 0,
                        minWidth: "0px",
                        marginLeft: "-3px",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <LikeIcon filled={isLiked} />
                    </Button>
                    <Typography
                      sx={{
                        fontFamily: "inherit",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(61, 61, 61, 0.70)",
                        marginLeft: "2px",
                      }}
                    >
                      {reactionsCount}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "25px",
                      cursor: "pointer",
                    }}
                  >
                    <CommentIcon />
                    <Typography
                      sx={{
                        fontFamily: "inherit",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(61, 61, 61, 0.70)",
                        marginLeft: "2px",
                      }}
                    >
                      {postComments?.length > 0 ? postComments?.length : 0}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginLeft: "25px",
                    }}
                    onClick={(e) => handleSharePost(e)}
                  >
                    <ShareIcon color={"#3D3D3D"} opacity />
                  </Grid>
                </Grid>
              </Grid>
              {postDetails?.type !== "image" &&
                postDetails?.type !== "video" &&
                window?.innerWidth > 768 && (
                  <Grid item>
                    <Grid
                      sx={{
                        height: "19px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: postDetails?.phase?.color,
                        borderRadius: "2px",
                        padding: "0px 5px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "inherit",
                          fontSize: "12px",
                          fontWeight: "500",
                          color: postDetails?.phase?.text_color
                            ? postDetails?.phase?.text_color
                            : "rgba(61, 61, 61, 0.80)",
                        }}
                      >
                        {postDetails?.phase?.title?.charAt(0).toUpperCase() +
                          postDetails?.phase?.title?.slice(1)}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Grid>
          {postDetails?.description && postDetails?.type === "image" && (
            <Grid item xs={12} sx={{ margin: "10px 0px" }}>
              <Typography
                dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                sx={{
                  color: "rgba(61, 61, 61, 0.70)",
                  fontFamily: "inherit",
                  fontWeight: "500",
                  fontSize: "13px",

                  "& p": {
                    margin: "0 !important",
                  },
                }}
              />
            </Grid>
          )}
          {access_token && (
            <Grid
              item
              xs={12}
              sm={11}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Avatar
                alt={"User"}
                src="/"
                sx={{
                  width: 32,
                  height: 32,
                  backgroundColor: "#1976d2",
                  margin: "10px 5px 10px 0px",
                }}
              />
              <Box
                sx={{
                  width: {
                    xs: "90%",
                    sm: "100%",
                  },
                }}
              >
                <Formik
                  initialValues={{
                    postComment: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikBag) =>
                    handleSubmit(values, formikBag)
                  }
                >
                  {({ values, handleChange, handleBlur, touched, errors }) => (
                    <Form>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <TextField
                          name="postComment"
                          placeholder="Add a comment..."
                          variant="standard"
                          value={values.postComment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.postComment && Boolean(errors.postComment)
                          }
                          helperText={
                            touched.postComment ? errors.postComment : ""
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton type="submit" edge="end">
                                  <SendIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            width: "100%",
                            fontSize: "12px",
                            fontFamily: "inherit",
                            color: "#3D3D3D",
                            "& .MuiInput-root": {
                              color: "#3D3D3D",
                            },
                            "& .MuiInput-input": {
                              fontSize: "12px",
                              fontFamily: "inherit",
                              color: "#3D3D3D",
                            },
                            ".MuiFormHelperText-root.Mui-error": {
                              fontSize: "10px",
                            },
                            "& .MuiInput-root:before": {
                              borderColor: "#3D3D3D33",
                            },
                            "& .Mui-error:before": {
                              borderColor: "#d32f2f !important",
                            },
                            "& .MuiInput-root:after": {
                              borderColor: "#3D3D3D33",
                            },
                            "& input::placeholder": {
                              color: "#3D3D3D",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                            },
                          }}
                        />
                        {/* <Button
                          type="submit"
                          sx={{ padding: 0, minWidth: "0px", width: "20px" }}
                        >
                          <SendIcon />
                        </Button> */}
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Grid>
          )}

          <Grid item xs={12} sx={{ marginTop: "10px" }}>
            {isCommentsLoading ? (
              <Box>
                <LoadingSpinner pageHeight="100px" size={30} />
              </Box>
            ) : postComments?.length > 0 ? (
              postComments?.map((comment, index) => {
                return (
                  <Box key={index}>
                    <CommentComponent item={comment} />
                  </Box>
                );
              })
            ) : (
              <Box>No comments available</Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <SharePopupComponent
        shareOptionsModal={shareOptionsModal}
        setShareOptionsModal={setShareOptionsModal}
        postDetails={postDetails}
      />
      {!access_token && <BottomSignInPrompt />}
    </Grid>
  );
};

export default PostDetailsPage;
