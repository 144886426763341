import { createSlice } from "@reduxjs/toolkit";

import {
  addPostComment,
  addPostReaction,
  getAboutUsDetails,
  getHomeContent,
  getHomeFilters,
  getPostComments,
  getPostDetails,
  getPostReactions,
  getUpcomingPhases,
} from "../../config/api";

const initialState = {
  isLoading: false,
  isCommentsLoading: false,
  isReactionsLoading: false,
  expandedBottomPrompt: false,
  watchedTimelineVideo: false,
  error: "",
  phaseFilter: [],
  tagsFilter: [],
  upcomingPhases: [],
  homeContent: {},
  postDetails: {},
  postComments: [],
  postReactions: [],
  headerFilterObjectState: {},
  aboutUsDetails: {},
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = "";
      state.postDetails = {};
      state.postComments = [];
      state.postReactions = [];
    },

    // START LOADING
    startCommentsLoading(state) {
      state.isCommentsLoading = true;
      state.error = "";
    },

    // START LOADING
    startReactionsLoading(state) {
      state.isReactionsLoading = true;
      state.error = "";
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isCommentsLoading = false;
      state.isReactionsLoading = false;
      state.error = action?.payload;
    },

    fetchHomeContentSuccess: (state, action) => {
      state.isLoading = false;
      state.homeContent = action.payload.data;
    },

    fetchHomeFiltersSuccess: (state, action) => {
      state.isLoading = false;
      state.phaseFilter = action.payload.data.phases;
      state.tagsFilter = action.payload.data.tags;
    },

    fetchUpcomingPhasesSuccess: (state, action) => {
      state.isLoading = false;
      state.upcomingPhases = action.payload.data;
    },

    fetchPostDetailsSuccess: (state, action) => {
      state.isLoading = false;
      state.postDetails = action.payload.data;
    },

    fetchPostCommentsSuccess: (state, action) => {
      state.isCommentsLoading = false;
      state.postComments = action.payload.data;
    },
    fetchPostReactionsSuccess: (state, action) => {
      state.isReactionsLoading = false;
      state.postReactions = action.payload.data;
    },
    headerFilterObject: (state, action) => {
      state.headerFilterObjectState = action.payload;
    },
    setExpandedBottomPrompt: (state, action) => {
      state.expandedBottomPrompt = action.payload;
    },
    setWatchedTimelineVideo: (state, action) => {
      state.watchedTimelineVideo = action.payload;
    },
    fetchAboutUsDetailsSuccess: (state, action) => {
      state.isLoading = false;
      state.aboutUsDetails = action.payload.data;
    },
  },
});
export const {
  headerFilterObject,
  setExpandedBottomPrompt,
  setWatchedTimelineVideo,
} = homeSlice.actions;

export function fetchHomeContent(data) {
  return async (dispatch) => {
    dispatch(homeSlice.actions.startLoading());
    try {
      const res = await getHomeContent(data);
      if (res.status === 200) {
        dispatch(homeSlice.actions.fetchHomeContentSuccess(res.data));
      } else {
        dispatch(homeSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(homeSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}
export function fetchHomeFilters() {
  return async (dispatch) => {
    dispatch(homeSlice.actions.startLoading());
    try {
      const res = await getHomeFilters();
      if (res.status === 200) {
        dispatch(homeSlice.actions.fetchHomeFiltersSuccess(res.data));
      } else {
        dispatch(homeSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(homeSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}

export function fetchUpcomingPhases() {
  return async (dispatch) => {
    dispatch(homeSlice.actions.startLoading());
    try {
      const res = await getUpcomingPhases();
      if (res.status === 200) {
        dispatch(homeSlice.actions.fetchUpcomingPhasesSuccess(res.data));
      } else {
        dispatch(homeSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(homeSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}

export function fetchPostDetails(id) {
  return async (dispatch) => {
    dispatch(homeSlice.actions.startLoading());
    try {
      const res = await getPostDetails(id);
      if (res.status === 200) {
        dispatch(homeSlice.actions.fetchPostDetailsSuccess(res.data));
      } else {
        dispatch(homeSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(homeSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}

export function fetchPostComments(id) {
  return async (dispatch) => {
    dispatch(homeSlice.actions.startCommentsLoading());
    try {
      const res = await getPostComments(id);
      if (res.status === 200) {
        dispatch(homeSlice.actions.fetchPostCommentsSuccess(res.data));
      } else {
        dispatch(homeSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(homeSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}

export function addPostComments(
  data,
  postId,
  isHomepage,
  headerFilterObjectState
) {
  return async (dispatch) => {
    dispatch(homeSlice.actions.startCommentsLoading());
    try {
      const res = await addPostComment(data);
      if (res.status === 200) {
        dispatch(fetchPostComments(postId));
        if (isHomepage) {
          dispatch(fetchHomeContent(headerFilterObjectState));
        }
      } else {
        dispatch(homeSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(homeSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}

export function fetchPostReactions(id) {
  return async (dispatch) => {
    dispatch(homeSlice.actions.startReactionsLoading());
    try {
      const res = await getPostReactions(id);
      if (res.status === 200) {
        dispatch(homeSlice.actions.fetchPostReactionsSuccess(res.data));
      } else {
        dispatch(homeSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(homeSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}
export function addPostReactions(
  data,
  postId,
  isHomepage,
  headerFilterObjectState
) {
  return async (dispatch) => {
    dispatch(homeSlice.actions.startReactionsLoading());
    try {
      const res = await addPostReaction(data);

      if (res.status === 200) {
        dispatch(fetchPostReactions(postId));
        if (isHomepage) {
          dispatch(fetchHomeContent(headerFilterObjectState));
        }
      } else {
        dispatch(homeSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(homeSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}
// FETCH ABOUT US DETAILS
export function fetchAboutUsDetails(data) {
  return async (dispatch) => {
    dispatch(homeSlice.actions.startLoading());
    try {
      const res = await getAboutUsDetails(data);
      if (res.status === 200) {
        dispatch(homeSlice.actions.fetchAboutUsDetailsSuccess(res.data));
      } else {
        dispatch(homeSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(homeSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}
export default homeSlice.reducer;
