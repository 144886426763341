import React from "react";
//css
import "./loader.css";
const LogoLoadingSpinner = () => {
  return (
    <div className="animation-root">
      <div className="animation-container">
        <div className="circle"></div>

        <div className="animation-content">
          <div className="shape-2 shape-container"></div>
          <div className="shape-1 shape-container"></div>
        </div>
      </div>
    </div>
  );
};

export default LogoLoadingSpinner;
