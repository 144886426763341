import React, { useEffect } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
//Redux/ Api
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderDetails } from "../../redux/slices/experiencesSlice";
//Icons
import { ChevronLeft } from "@mui/icons-material";

const OrderDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { orderDetails } = useSelector((state) => state.experiences);

  useEffect(() => {
    dispatch(fetchOrderDetails(id));
  }, [id]);

  return window?.innerWidth < 768 ? (
    <Grid container sx={{ padding: "10px" }}>
      <Box
        onClick={() => navigate(-1)}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          cursor: "pointer",
          padding: "2px 10px",
          color: "#3D475C",
          fontWeight: "600",
        }}
      >
        <ChevronLeft
          sx={{ fontSize: "22px", marginLeft: "-5px", color: "#3D475C" }}
        />
        Order Details
      </Box>
      <Grid item xs={12} sx={{ marginTop: "15px" }}>
        <Grid container columnSpacing={1}>
          <Grid
            item
            xs={3}
            sx={{
              maxWidth: "73px",
              height: "70px",
              borderRadius: "5px",
              backgroundImage: `url(${orderDetails?.image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              marginLeft: "25px",
            }}
          />
          <Grid
            item
            xs={7}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ fontFamily: "inherit", fontSize: "15px", color: "#3D3D3D" }}
            >
              {orderDetails?.name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Divider
              sx={{
                backgroundColor: "#E6E7E8",
                width: "100%",
                margin: "10px 0px",
              }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "0px 10px" }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0px",
            }}
          >
            <Typography
              sx={{ color: "#898989", fontSize: "15px", fontFamily: "inherit" }}
            >
              Item
            </Typography>
            <Typography
              sx={{ color: "#3D3D3D", fontSize: "15px", fontFamily: "inherit" }}
            >
              {orderDetails?.item}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0px",
            }}
          >
            <Typography
              sx={{ color: "#898989", fontSize: "15px", fontFamily: "inherit" }}
            >
              Issue date
            </Typography>
            <Typography
              sx={{ color: "#3D3D3D", fontSize: "15px", fontFamily: "inherit" }}
            >
              {orderDetails?.issue_date}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0px",
            }}
          >
            <Typography
              sx={{ color: "#898989", fontSize: "15px", fontFamily: "inherit" }}
            >
              Email
            </Typography>
            <Typography
              sx={{ color: "#3D3D3D", fontSize: "15px", fontFamily: "inherit" }}
            >
              {orderDetails?.user_email}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0px",
            }}
          >
            <Typography
              sx={{ color: "#898989", fontSize: "15px", fontFamily: "inherit" }}
            >
              Currency
            </Typography>
            <Typography
              sx={{ color: "#3D3D3D", fontSize: "15px", fontFamily: "inherit" }}
            >
              {orderDetails?.currency}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(61, 61, 61, 0.80)",
                fontSize: "15px",
                fontFamily: "inherit",
              }}
            >
              Quantity
            </Typography>
            <Typography
              sx={{ color: "#3D3D3D", fontSize: "15px", fontFamily: "inherit" }}
            >
              {orderDetails?.nbOfSeats}
            </Typography>
          </Grid>
          <Divider
            sx={{
              backgroundColor: "#E6E7E8",
              width: "100%",
            }}
          />
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(61, 61, 61, 0.80)",
                fontSize: "15px",
                fontFamily: "inherit",
              }}
            >
              Subtotal
            </Typography>
            <Typography
              sx={{ color: "#3D3D3D", fontSize: "15px", fontFamily: "inherit" }}
            >
              ${orderDetails?.sub_total}
            </Typography>
          </Grid>
          <Divider
            sx={{
              backgroundColor: "#E6E7E8",
              width: "100%",
            }}
          />
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "15px 0px",
            }}
          >
            <Typography
              sx={{
                color: "#202020",
                fontSize: "15px",
                fontWeight: "600",
                fontFamily: "inherit",
              }}
            >
              Total
            </Typography>
            <Typography
              sx={{
                color: "#202020",
                fontSize: "15px",
                fontWeight: "600",
                fontFamily: "inherit",
              }}
            >
              ${orderDetails?.total}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      sx={{
        padding: { xs: "0px 20px", sm: "0px 50px", lg: "10px 60px" },
        marginTop: "20px",
      }}
    >
      <Grid
        item
        sm={12}
        sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
      >
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => navigate(-1)}
        >
          <ChevronLeft
            sx={{ fontSize: "28px", marginLeft: "-10px", color: "#3D475C" }}
          />
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#3D475C",
              fontSize: "23px",
              fontFamily: "Fraunces",
              fontWeight: "600",
            }}
          >
            Order Details
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        sm={12}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          margin: "30px 0px",
        }}
      >
        <Typography
          sx={{
            color: "#3D475C",
            fontSize: "22px",
            fontFamily: "inherit",
            fontWeight: "500",
          }}
        >
          Order
        </Typography>
        <Typography
          sx={{
            color: "#3D475C",
            fontSize: "23px",
            fontFamily: "inherit",
            fontWeight: "600",
            marginLeft: "5px",
          }}
        >
          {orderDetails?.item}
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <Grid container>
          <Grid item sm={1}>
            <Typography
              sx={{
                textAlign: "left",
                color: "#9499A1",
                fontFamily: "inherit",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              ITEM
            </Typography>
          </Grid>
          <Grid item sm={3.6}>
            <Typography
              sx={{
                textAlign: "center",
                color: "#9499A1",
                fontFamily: "inherit",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              ITEM NAME
            </Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography
              sx={{
                textAlign: "center",
                color: "#9499A1",
                fontFamily: "inherit",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              QUANTITY
            </Typography>
          </Grid>
          <Grid item sm={2.4}>
            <Typography
              sx={{
                textAlign: "center",
                color: "#9499A1",
                fontFamily: "inherit",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              DATE
            </Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography
              sx={{
                textAlign: "center",
                color: "#9499A1",
                fontFamily: "inherit",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              TOTAL
            </Typography>
          </Grid>
          <Grid item sm={1}>
            <Typography
              sx={{
                textAlign: "center",
                color: "#9499A1",
                fontFamily: "inherit",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              Status
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}
        >
          <Grid
            item
            sm={1}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${orderDetails?.image})`,
                // backgroundImage: `url(${orderDetails?.image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "52px",
                width: "52px",
              }}
            />
          </Grid>
          <Grid item sm={3.6}>
            <Typography
              sx={{
                textAlign: "center",
                color: "#3D3D3D",
                fontFamily: "inherit",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              {orderDetails?.name}
            </Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography
              sx={{
                textAlign: "center",
                color: "#3D3D3D",
                fontFamily: "inherit",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              {orderDetails?.nbOfSeats}
            </Typography>
          </Grid>
          <Grid item sm={2.4}>
            <Typography
              sx={{
                textAlign: "center",
                color: "#3D3D3D",
                fontFamily: "inherit",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              {orderDetails?.issue_date}
            </Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography
              sx={{
                textAlign: "center",
                color: "#3D3D3D",
                fontFamily: "inherit",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              ${orderDetails?.total}
            </Typography>
          </Grid>
          <Grid item sm={1}>
            <Typography
              sx={{
                textAlign: "center",
                color: "#3D3D3D",
                fontFamily: "inherit",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              {orderDetails?.status?.charAt(0).toUpperCase() +
                orderDetails?.status?.slice(1)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        sx={{
          backgroundColor: "rgba(61, 61, 61, 0.20)",
          width: "100%",
          margin: "20px 0px",
        }}
      />
      <Grid container columnGap={{ md: 4, lg: 4 }} sx={{ marginTop: "30px" }}>
        <Grid item sm={12} md={6} lg={5.5}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontWeight: "500",
                  color: "#898989",
                  fontSize: "17px",
                }}
              >
                Issue date
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  color: " #3D3D3D",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                {orderDetails?.issue_date}
              </Typography>
            </Box>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontWeight: "500",
                  color: "#898989",
                  fontSize: "17px",
                }}
              >
                Email
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  color: " #3D3D3D",
                  fontSize: "18px",
                  fontWeight: "500",
                  maxWidth:
                    window.innerWidth < 1000 && window.innerWidth > 900
                      ? "200px"
                      : "600px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {orderDetails?.user_email}
              </Typography>
            </Box>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontWeight: "500",
                  color: "#898989",
                  fontSize: "17px",
                }}
              >
                Currency
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  color: " #3D3D3D",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                {orderDetails?.currency}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item sm={12} md={5} lg={6}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontWeight: "500",
                  color: "rgba(61, 61, 61, 0.80)",
                  fontSize: "17px",
                }}
              >
                Quantity
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  color: "#202020",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                {orderDetails?.nbOfSeats}
              </Typography>
            </Box>
          </Grid>
          <Divider
            sx={{
              backgroundColor: "rgba(61, 61, 61, 0.20)",
              width: "100%",
              margin: "10px 0px",
            }}
          />
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontWeight: "500",
                  color: "rgba(61, 61, 61, 0.80)",
                  fontSize: "17px",
                }}
              >
                Subtotal
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  color: " #202020",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                ${orderDetails?.sub_total}
              </Typography>
            </Box>
          </Grid>
          <Divider
            sx={{
              backgroundColor: "rgba(61, 61, 61, 0.20)",
              width: "100%",
              margin: "10px 0px",
            }}
          />
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontWeight: "600",
                  color: "#202020",
                  fontSize: "17px",
                }}
              >
                Total
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  color: "#202020",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                ${orderDetails?.total}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderDetailsPage;
