import React, { useState } from "react";

//CSS
import "./auth.css";

//MUI components
import { Box, Container, Grid, Button } from "@mui/material";
//Validation and Formik
import * as yup from "yup";
import { Form, Formik } from "formik";
//components
import AuthInputField from "../../components/inputs/AuthInputFIeld";
import { useNavigate } from "react-router-dom";
//Icons
import ForgotPasswordIcon from "../../assets/icons/ForgotPasswordIcon";
//Redux/ Api
import { forgotPassword } from "../../config/api";
import { useDispatch, useSelector } from "react-redux";
import { feedbackContentPage } from "../../redux/slices/authSlice";
//Header Logo
import HeaderLogo from "../../components/headerLogo/HeaderLogo";
//Helmet Component
import HelmetPage from "../../components/helmetPage/HelmetPage";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { winery } = useSelector((state) => state.auth);

  const [errorMessage, setErrorMessage] = useState("");

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
  });
  const handleSubmit = async (values) => {
    await forgotPassword(values).then((res) => {
      if (res.status === 200) {
        navigate("/feedback");
        dispatch(
          feedbackContentPage({
            type: "forgot_password",
            title: "Reset Password",
            message: res?.data?.error?.message,
          })
        );
      } else {
        if (res?.data?.message === "email_not_found") {
          setErrorMessage("This email does not exist");
        } else if (res?.data?.message === "invalid_token") {
          setErrorMessage("This token is invalid");
        } else {
          setErrorMessage("Something went wrong, please try again later.");
        }
      }
    });
  };

  return (
    <Container className="main-container">
      <HelmetPage pageTitle={"Forgot Password"} />
      <HeaderLogo winery={winery} />

      <Box className="form-container">
        <Box className="header-icon">
          <ForgotPasswordIcon />
        </Box>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            values,
            handleChange,
            handleBlur,
            touched,
            errors,
            isSubmitting,
          }) => (
            <Form>
              <Grid container className="grid-container">
                <div className="form-title">Forgot Password</div>

                <Grid item xs={12}>
                  <AuthInputField
                    name="email"
                    placeholder="Email Address"
                    type="email"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email ? errors.email : ""}
                  />
                </Grid>
                {errorMessage && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      textAlign: "left",
                      width: "100%",
                      color: "#d32f2f",
                      fontSize: "15px",
                      maxWidth: { xs: "250px", sm: "310px", md: "400px" },
                    }}
                  >
                    <Box>{errorMessage}</Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    // disabled={isSubmitting}
                    sx={{
                      backgroundColor: "#3D3D3D",
                      borderRadius: "10px",
                      marginTop: "10px",
                      textTransform: "none",
                      height: { xs: "40px", sm: "45px" },
                      width: { xs: "250px", sm: "310px", md: "350px" },
                      fontSize: { xs: "16px", sm: "20px" },
                      fontFamily: "inherit",
                      "&:hover": {
                        backgroundColor: "#c88080ff",
                      },
                    }}
                  >
                    Send Reset Link
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <Box
                    sx={{
                      textDecoration: "none",
                      marginTop: "10px",
                      fontSize: { xs: "10px", sm: "18px" },
                      color: "rgba(0, 0, 0, 0.60)",
                    }}
                  >
                    Already have an account?
                  </Box>
                  <Box
                    onClick={() => navigate(`/login`)}
                    sx={{
                      color: "#3D3D3D",
                      fontSize: { xs: "12px", sm: "19px" },
                      fontWeight: "600",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                  >
                    Sign in
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
