import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";

//Icons
import SuccessPaymentIcon from "../../assets/icons/SuccessPaymentIcon";

const OrderSuccessPage = () => {
  const navigate = useNavigate();
  const { successfulPayment } = useSelector((state) => state.experiences);
  const { access_token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!access_token) {
      navigate("/");
    }
  }, [access_token]);

  return window.innerWidth < 768 ? (
    <Grid container sx={{ padding: "10px" }}>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <SuccessPaymentIcon type={successfulPayment?.type} />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "10px" }}>
        <Typography
          sx={{
            fontFamily: "inherit",
            fontSize: "26px",
            color: "#191818",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {successfulPayment?.status === "paid"
            ? "Payment successful!"
            : "Thank you for your payment."}
        </Typography>
        <Typography
          sx={{
            fontFamily: "inherit",
            fontSize: "14px",
            color: "#7A7A7A",
            fontWeight: "400",
            textAlign: "center",
          }}
        >
          {successfulPayment?.status === "paid"
            ? "Thank you for your transaction."
            : "An email will be sent to you as soon as your payment is finalized."}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ padding: "0px 5px" }}>
        <Grid container sx={{ alignItems: "flex-start", marginTop: "20px" }}>
          <Grid item xs={9} sx={{ flexDirection: "column" }}>
            <Typography
              sx={{
                fontFamily: "inherit",
                fontSize: "15px",
                color: "#362629",
                fontWeight: "600",
              }}
            >
              {successfulPayment?.experience}
            </Typography>
            <Typography
              sx={{
                fontFamily: "inherit",
                fontSize: "13px",
                color: "#3D3D3D",
              }}
            >
              Number of persons : {successfulPayment?.nbOfSeats}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                fontFamily: "inherit",
                fontSize: "16px",
                color: "#362629",
                fontWeight: "600",
              }}
            >
              $ {successfulPayment?.total_amount}
            </Typography>
          </Grid>
        </Grid>
        <Divider
          sx={{
            backgroundColor: "#E6E7E8",
            width: "100%",
            margin: "10px 0px",
          }}
        />
        <Grid
          item
          xs={12}
          sx={{
            marginTop: "40px",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{
              fontFamily: "inherit",
              fontSize: "14px",
              color: "#7A7A7A",
              minWidth: "125px",
            }}
          >
            Transaction Date
          </Typography>
          <Typography
            sx={{
              fontFamily: "inherit",
              fontSize: "14px",
              color: "#362629",
              fontWeight: "600",
              width: "100%",
              textAlign: "right",
            }}
          >
            {moment(successfulPayment?.added_at).format("dddd, DD MMM YYYY")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{
              fontFamily: "inherit",
              fontSize: "14px",
              color: "#7A7A7A",
              minWidth: "125px",
            }}
          >
            Order number
          </Typography>
          <Typography
            sx={{
              fontFamily: "inherit",
              fontSize: "14px",
              color: "#362629",
              fontWeight: "600",
              width: "100%",
              textAlign: "right",
            }}
          >
            {successfulPayment?.uuid}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#CBC2A8",
                borderRadius: "5px",
                textTransform: "none",
                marginTop: "20px",
                height: "40px",
                width: "100%",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "inherit",
                "&:hover": {
                  backgroundColor: "#CBC2A8",
                },
              }}
              onClick={() => navigate("/all-orders")}
            >
              Back To My Orders
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container sx={{ padding: "10px 70px 20px 70px" }}>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <SuccessPaymentIcon type={successfulPayment?.type} />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "10px" }}>
        <Typography
          sx={{
            fontFamily: "inherit",
            fontSize: "26px",
            color: "#191818",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {successfulPayment?.status === "paid"
            ? "Payment successful!"
            : "Thank you for your transaction."}
        </Typography>
        <Typography
          sx={{
            fontFamily: "inherit",
            fontSize: "14px",
            color: "#7A7A7A",
            fontWeight: "400",
            textAlign: "center",
          }}
        >
          {successfulPayment?.status === "paid"
            ? "Thank you for your transaction."
            : "An email will be sent to you shortly."}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          padding: "20px 75px",
          border: "1px solid var(--neutral-300, #D7D7D7)",
          borderRadius: "12px",
          marginTop: "20px",
        }}
      >
        <Grid
          container
          sx={{
            alignItems: "flex-start",
            marginTop: "20px",
          }}
        >
          <Grid item xs={9} sx={{ flexDirection: "column" }}>
            <Grid container sx={{ display: "flex", alignItems: "center" }}>
              <Grid
                item
                sx={{
                  width: { md: "80px", lg: "106px" },
                  height: { md: "80px", lg: "106px" },
                  marginRight: "10px",
                  borderRadius: "5px",
                  backgroundImage: `url(${successfulPayment?.image})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
              <Grid item sx={{ flexDirection: "column" }}>
                <Typography
                  sx={{
                    fontFamily: "inherit",
                    fontSize: { md: "20px", lg: "24px" },
                    color: "#362629",
                    fontWeight: "600",
                  }}
                >
                  {successfulPayment?.experience}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "inherit",
                    fontSize: { md: "16px", lg: "18px" },
                    color: "#3D3D3D",
                    marginTop: "5px",
                  }}
                >
                  Number of persons : {successfulPayment?.nbOfSeats}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                fontFamily: "inherit",
                fontSize: { md: "20px", lg: "24px" },
                color: "#362629",
                fontWeight: "600",
                textAlign: "right",
                height: { md: "80px", lg: "106px" },
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              $ {successfulPayment?.total_amount}
            </Typography>
          </Grid>
        </Grid>
        <Divider
          sx={{
            backgroundColor: "#E6E7E8",
            width: "100%",
            margin: "20px 0px",
          }}
        />
        <Grid
          item
          xs={12}
          sx={{
            marginTop: "40px",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{
              fontFamily: "inherit",
              fontSize: { md: "14px", lg: "20px" },
              color: "#7A7A7A",
              minWidth: { sm: "125px", lg: "175px" },
            }}
          >
            Transaction Date
          </Typography>
          <Typography
            sx={{
              fontFamily: "inherit",
              fontSize: { md: "14px", lg: "20px" },
              color: "#362629",
              fontWeight: "600",
              width: "100%",
              textAlign: "right",
            }}
          >
            {moment(successfulPayment?.added_at).format("dddd, DD MMM YYYY")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            margin: "30px 0px 20px 0px",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{
              fontFamily: "inherit",
              fontSize: { md: "14px", lg: "20px" },
              color: "#7A7A7A",
              minWidth: { sm: "125px", lg: "175px" },
            }}
          >
            Order number
          </Typography>
          <Typography
            sx={{
              fontFamily: "inherit",
              fontSize: { md: "14px", lg: "20px" },
              color: "#362629",
              fontWeight: "600",
              width: "100%",
              textAlign: "right",
            }}
          >
            {successfulPayment?.uuid}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Divider
            sx={{
              backgroundColor: "#E6E7E8",
              width: "80%",
              margin: "20px 0px",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#CBC2A8",
              borderRadius: "5px",
              textTransform: "none",
              marginTop: "20px",
              height: "56px",
              maxWidth: "600px",
              width: "100%",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "inherit",
              "&:hover": {
                backgroundColor: "#CBC2A8",
              },
            }}
            onClick={() => navigate("/all-orders")}
          >
            Back To My Orders
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderSuccessPage;
