import React, { useEffect, useState } from "react";
import { Button, Typography, Box } from "@mui/material";
//Icons
import ChevronRightHome from "../../assets/icons/ChevronRightHome";
//Naviagtion
import { useNavigate } from "react-router-dom";
//Redux
import { useSelector } from "react-redux";
import HeaderLogo from "../../components/headerLogo/HeaderLogo";
import OnboardingIcon from "../../assets/icons/desktopIcons/OnboardingIcon";
import OnboardingChevronRight from "../../assets/icons/desktopIcons/OnboardingChevronRight";

const HomePageContent = () => {
  const { winery } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevStep) =>
      Math.min(prevStep + 1, winery?.tips?.length - 1)
    );
    if (activeStep === winery?.tips?.length - 1) {
      localStorage.setItem("onboarding_flow", true);
      navigate("/home");
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };
  const onboarding_flow_checker = localStorage.getItem("onboarding_flow");

  useEffect(() => {
    if (onboarding_flow_checker === "true") {
      navigate("/");
    }
  }, [onboarding_flow_checker]);

  return (
    <>
      {window.innerWidth < 768 ? (
        <>
          {" "}
          <Box
            sx={{
              backgroundColor: "#DDDDD3",
              height: "100vh",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxHeight: "100vh",
                  backgroundImage: `url(${winery?.tips[activeStep]?.image})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "100%",
                }}
              />
              <Box
                sx={{
                  backgroundColor: "#CBC2A7",
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  height: "30vh",
                  borderTopLeftRadius: "50%",
                  borderTopRightRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: "inherit",
                    textAlign: "center",
                    marginTop: "30px",
                    color: "#3D3D3D",
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "0px 20px",
                  }}
                >
                  {winery?.tips[activeStep]?.description}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    marginTop: "20px",
                  }}
                >
                  {activeStep === 0 ? (
                    <>
                      <Button
                        onClick={handleNext}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "48px",
                          width: "250px",
                          textTransform: "none",
                          backgroundColor: "#3D3D3D",
                          borderRadius: "8px",
                          fontFamily: "inherit",
                          "&:hover": {
                            backgroundColor: "#3D3D3D",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            color: "white",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Get started
                        </Box>
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={handleBack}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "48px",
                          width: "60px",
                          textTransform: "none",
                          backgroundColor: "none",
                          fontFamily: "inherit",
                          "&:hover": {
                            backgroundColor: "none",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            color: "#3D3D3D",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Back
                        </Box>
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "48px",
                          width: "88px",
                          backgroundColor: "#3D3D3D",
                          borderRadius: "8px",
                          fontFamily: "inherit",
                          "&:hover": {
                            backgroundColor: "#3D3D3D",
                          },
                        }}
                      >
                        <ChevronRightHome />
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            backgroundImage: `url(${winery?.desktop_tips[activeStep]?.image})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.55)",
              backdropFilter: "blur(4px)",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ margin: "30px 0px 100px 0px" }}>
              <HeaderLogo winery={winery} />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "30px",
                  color: "#FFFFFF",
                  fontFamily: "inherit",
                  fontWeight: "600",
                  textAlign: "center",
                  padding: "0px 50px",
                }}
              >
                {winery?.tips[activeStep]?.description}
              </Typography>
            </Box>
            <Box>
              <OnboardingIcon />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                position: "fixed",
                bottom: 60,
              }}
            >
              {activeStep !== 0 ? (
                <Box
                  sx={{ marginLeft: "50px", cursor: "pointer" }}
                  onClick={handleBack}
                >
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontSize: "19px",
                      fontWeight: "600",
                      fontFamily: "inherit",
                    }}
                  >
                    Back
                  </Typography>
                </Box>
              ) : (
                <Box />
              )}

              <Box
                sx={{ marginRight: "50px", cursor: "pointer" }}
                onClick={handleNext}
              >
                <OnboardingChevronRight />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default HomePageContent;
