import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  List,
  ListSubheader,
  Select,
  Typography,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Components
import HomepageItem from "../../components/homepage/HomepageItem";
import TimelineComponent from "../../components/timeline/Timeline";
import TimelineRowItem from "../../components/timeline/TimelineRowItem";
//Redux/ Api
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSelectedPhaseTimelineContent,
  fetchTimelineArticles,
  fetchTimelineContent,
} from "../../redux/slices/timelineSlice";
import TimelineArticleItem from "../../components/timeline/TimelineArticleItem";
import { monthsTimelineFilter } from "../../utils/MonthsUtils";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import ChevronDownExperiences from "../../assets/icons/ChevronDownExperiences";

const Timeline = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { watchedTimelineVideo } = useSelector((state) => state.home);
  const { winery } = useSelector((state) => state.auth);

  const [scrolling, setScrolling] = useState(false);
  const {
    timelineContent,
    selectedPhase,
    selectedPhaseTimelineContent,
    selectedPhaseMonthTimelineContent,
    phaseMonthTimelineContentLoading,
    timelineArticles,
  } = useSelector((state) => state.timeline);

  const [selectedMonth, setSelectedMonth] = useState("");
  const handleChange = (event) => {
    if (event.target.value === "Month") {
      setSelectedMonth("");
    } else {
      setSelectedMonth(event.target.value);
    }
  };

  useEffect(() => {
    if (winery?.hide_timeline && !winery?.hide_home) {
      navigate("/");
    } else if (winery?.hide_timeline) {
      navigate("/experiences");
    }
  }, [winery]);

  useEffect(() => {
    if (selectedPhase) {
      let obj = {
        slug: selectedPhase,
      };
      dispatch(fetchSelectedPhaseTimelineContent(obj));
    } else if (selectedMonth) {
      let obj = {
        month: selectedMonth,
      };
      dispatch(fetchSelectedPhaseTimelineContent(obj));
    } else {
      dispatch(fetchTimelineContent());
    }
  }, [selectedPhase, selectedMonth]);

  useEffect(() => {
    dispatch(fetchTimelineArticles());
  }, []);

  useEffect(() => {
    console.log("scroll event");
    const handleScroll = () => {
      console.log("scrolling");
      if (window.scrollY > 100) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {window.innerWidth > 768 && (
        <Typography
          sx={{
            fontSize: "30px",
            color: "#3D3D3D",
            fontFamily: "Fraunces",
            fontWeight: "600",
            marginBottom: "10px",
            paddingLeft: "10px",
            marginTop: "30px",
            padding: { xs: 0, sm: "0px 30px", lg: "0px 60px" },
          }}
        >
          Timeline
        </Typography>
      )}
      {window.innerWidth < 768 && timelineContent?.timeline?.length > 0 && (
        <Box
          // sx={{ position: "fixed", zIndex: 100, background: "white", top: 0 }}
          className={`timeline-container ${scrolling ? "scrolling" : ""}`}
          sx={{ top: selectedPhase || scrolling ? 0 : "-50%" }}
        >
          <TimelineComponent
            timelineContent={timelineContent}
            scrolling={scrolling}
          />
        </Box>
      )}
      {window.innerWidth < 768 ? (
        <>
          {selectedPhase ? (
            <Box sx={{ padding: "15px 20px" }}>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "inherit",
                    fontSize: "18px",
                    color: "#3D3D3D",
                    margin: "5px 0px",
                  }}
                >
                  {selectedPhaseTimelineContent?.intro?.title}
                </Typography>
              </Box>
              <Box sx={{ margin: "10px 0px" }}>
                <HomepageItem
                  item={selectedPhaseTimelineContent?.intro}
                  isTimeline
                />
              </Box>

              {selectedPhaseTimelineContent?.posts &&
                Object.keys(selectedPhaseTimelineContent?.posts).length > 0 && (
                  <>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          color: "#3D3D3D",
                          fontFamily: "inherit",
                          fontWeight: "600",
                        }}
                      >
                        Latest
                      </Typography>
                    </Box>
                    <TimelineRowItem
                      rowContent={selectedPhaseTimelineContent?.posts}
                    />
                  </>
                )}
            </Box>
          ) : (
            <Box sx={{ padding: "15px 20px" }}>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "inherit",
                    fontSize: "18px",
                    color: "#3D3D3D",
                    margin: "5px 0px",
                    fontWeight: "600",
                  }}
                >
                  {timelineContent?.video_timeline?.title}
                </Typography>
              </Box>
              <Box sx={{ margin: "10px 0px" }}>
                <HomepageItem
                  item={timelineContent?.video_timeline}
                  isTimeline
                />
              </Box>
              {timelineContent?.posts &&
                Object.keys(timelineContent?.posts).length > 0 && (
                  <>
                    <Box sx={{ marginTop: "5px" }}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          color: "#3D3D3D",
                          fontFamily: "inherit",
                          fontWeight: "600",
                        }}
                      >
                        Latest
                      </Typography>
                    </Box>
                    <TimelineRowItem rowContent={timelineContent?.posts} />
                  </>
                )}
            </Box>
          )}
        </>
      ) : (
        <>
          <Grid
            container
            sx={{ padding: { xs: 0, sm: "0px 30px", lg: "0px 60px" } }}
          >
            <Grid item xs={watchedTimelineVideo ? 8 : 12} order={1}>
              <Box
                // sx={{ position: "fixed", zIndex: 100, background: "white", top: 0 }}
                className={`timeline-container ${scrolling ? "scrolling" : ""}`}
                sx={{ top: selectedPhase ? 0 : "-50%" }}
              >
                <TimelineComponent
                  timelineContent={timelineContent}
                  scrolling={scrolling}
                />
              </Box>{" "}
              {watchedTimelineVideo && (
                <Grid
                  item
                  xs={12}
                  // sx={{ padding: { xs: "0px 30px", lg: "0px 50px" } }}
                  order={watchedTimelineVideo ? 3 : 2}
                >
                  <>
                    {selectedPhase ? (
                      <Box sx={{ padding: "10px" }}>
                        {/* <Box>
                      <Typography
                        sx={{
                          fontFamily: "inherit",
                          fontSize: "18px",
                          color: "#3D3D3D",
                          margin: "5px 0px",
                        }}
                      >
                        {selectedPhaseTimelineContent?.intro?.title}
                      </Typography>
                    </Box> */}
                        <Box sx={{ margin: "10px 0px" }}>
                          <HomepageItem
                            item={selectedPhaseTimelineContent?.intro}
                            isDesktop
                            // isTimeline
                          />
                        </Box>

                        {selectedPhaseTimelineContent?.posts &&
                          Object.keys(selectedPhaseTimelineContent?.posts)
                            .length > 0 && (
                            <>
                              <List
                                sx={{
                                  mb: 0,
                                  pb: 0,
                                  pt: 0,
                                  // overflowX: "scroll",
                                  // height: access_token ? "88vh" : "30vh",
                                }}
                              >
                                {Object.entries(
                                  selectedPhaseTimelineContent?.posts
                                ).map(([month, postsInMonth]) => (
                                  <Box key={month}>
                                    <ListSubheader
                                      sx={{
                                        bgcolor: "background.paper",
                                        padding: 0,
                                        fontFamily: "inherit",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          height: "35px",
                                          width: "100%",
                                          backgroundColor:
                                            "rgba(245, 245, 245, 0.50)",
                                          boxShadow:
                                            "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontFamily: "inherit",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                            color: "#3D3D3D",
                                            padding: "0px 10px",
                                          }}
                                        >
                                          {month}
                                        </Typography>
                                      </Box>
                                    </ListSubheader>

                                    {postsInMonth?.map((post, index) => (
                                      <Box
                                        key={index}
                                        sx={{
                                          padding: {
                                            xs: "15px 20px",
                                            sm: "0px",
                                          },
                                        }}
                                      >
                                        <HomepageItem
                                          item={post}
                                          isDesktop={
                                            window.innerWidth < 768
                                              ? false
                                              : true
                                          }
                                        />
                                      </Box>
                                    ))}
                                  </Box>
                                ))}
                              </List>
                            </>
                          )}
                      </Box>
                    ) : (
                      <Box sx={{ padding: "10px" }}>
                        {/* <Box>
                      <Typography
                        sx={{
                          fontFamily: "inherit",
                          fontSize: "18px",
                          color: "#3D3D3D",
                          margin: "5px 0px",
                          fontWeight: "600",
                        }}
                      >
                        {timelineContent?.video_timeline?.title}
                      </Typography>
                    </Box> */}

                        <Grid
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "inherit",
                              fontSize: "16px",
                              color: "rgba(61, 61, 61, 0.70)",
                            }}
                          >
                            Sort by:
                          </Typography>
                          <Box sx={{ marginLeft: "10px" }}>
                            <Select
                              value={selectedMonth}
                              onChange={handleChange}
                              IconComponent={ExpandMoreIcon}
                              displayEmpty={true}
                              renderValue={(value) =>
                                selectedMonth ? value : "Month"
                              }
                              sx={{
                                width: "120px",
                                fontFamily: "inherit",
                                "& .MuiOutlinedInput-input": {
                                  padding: "3px 0px 3px 5px",
                                  fontFamily: "inherit",
                                },
                              }}
                              MenuProps={{
                                style: {
                                  maxHeight: "300px",
                                  fontFamily: "inherit",
                                },
                              }}
                            >
                              {monthsTimelineFilter.map((x) => {
                                return (
                                  <MenuItem
                                    value={x}
                                    key={x}
                                    sx={{
                                      color: "#3D3D3D",
                                      fontFamily: "inherit",
                                    }}
                                  >
                                    {x}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Box>
                        </Grid>
                        {phaseMonthTimelineContentLoading ? (
                          <LoadingSpinner pageHeight={"100px"} size={30} />
                        ) : (
                          <>
                            <List
                              sx={{
                                mb: 0,
                                pb: 0,
                                pt: 0,
                              }}
                            >
                              {selectedMonth ? (
                                <>
                                  {selectedPhaseMonthTimelineContent?.length >
                                  0 ? (
                                    <Box>
                                      <ListSubheader
                                        sx={{
                                          bgcolor: "background.paper",
                                          padding: 0,
                                          fontFamily: "inherit",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            height: "35px",
                                            width: "100%",
                                            backgroundColor:
                                              "rgba(245, 245, 245, 0.50)",
                                            boxShadow:
                                              "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: "20px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontFamily: "inherit",
                                              fontSize: "15px",
                                              fontWeight: "600",
                                              color: "#3D3D3D",
                                              padding: "0px 10px",
                                            }}
                                          >
                                            {selectedMonth}
                                          </Typography>
                                        </Box>
                                      </ListSubheader>

                                      {selectedPhaseMonthTimelineContent?.map(
                                        (postInMonth, index) => (
                                          <Box
                                            key={index}
                                            sx={{
                                              padding: {
                                                xs: "15px 20px",
                                                sm: "0px",
                                              },
                                            }}
                                          >
                                            <HomepageItem
                                              item={postInMonth}
                                              isDesktop={
                                                window.innerWidth < 768
                                                  ? false
                                                  : true
                                              }
                                            />
                                          </Box>
                                        )
                                      )}
                                    </Box>
                                  ) : (
                                    <>
                                      <Box
                                        sx={{
                                          marginTop: "10px",
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            color: "#3D3D3D",
                                            fontFamily: "inherit",
                                            fontSize: "14px",
                                          }}
                                        >
                                          No available posts for this month
                                        </Typography>
                                      </Box>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {timelineContent?.posts ? (
                                    Object.entries(timelineContent?.posts).map(
                                      ([month, postsInMonth]) => (
                                        <Box key={month}>
                                          <ListSubheader
                                            sx={{
                                              bgcolor: "background.paper",
                                              padding: 0,
                                              fontFamily: "inherit",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                height: "35px",
                                                width: "100%",
                                                backgroundColor:
                                                  "rgba(245, 245, 245, 0.50)",
                                                boxShadow:
                                                  "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                                                display: "flex",
                                                alignItems: "center",
                                                marginTop: "20px",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontFamily: "inherit",
                                                  fontSize: "15px",
                                                  fontWeight: "600",
                                                  color: "#3D3D3D",
                                                  padding: "0px 10px",
                                                }}
                                              >
                                                {month}
                                              </Typography>
                                            </Box>
                                          </ListSubheader>

                                          {postsInMonth?.map((post, index) => (
                                            <Box
                                              key={index}
                                              sx={{
                                                padding: {
                                                  xs: "15px 20px",
                                                  sm: "0px",
                                                },
                                              }}
                                            >
                                              <HomepageItem
                                                item={post}
                                                isDesktop={
                                                  window.innerWidth < 768
                                                    ? false
                                                    : true
                                                }
                                              />
                                            </Box>
                                          ))}
                                        </Box>
                                      )
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </List>
                          </>
                        )}
                      </Box>
                    )}
                  </>
                </Grid>
              )}
            </Grid>
            {!watchedTimelineVideo && (
              <Grid
                item
                xs={8}
                // sx={{ padding: { xs: "0px 30px", lg: "0px 50px" } }}
                order={watchedTimelineVideo ? 3 : 2}
              >
                <>
                  {selectedPhase ? (
                    <Box sx={{ padding: "10px" }}>
                      {/* <Box>
                      <Typography
                        sx={{
                          fontFamily: "inherit",
                          fontSize: "18px",
                          color: "#3D3D3D",
                          margin: "5px 0px",
                        }}
                      >
                        {selectedPhaseTimelineContent?.intro?.title}
                      </Typography>
                    </Box> */}
                      <Box sx={{ margin: "10px 0px" }}>
                        <HomepageItem
                          item={selectedPhaseTimelineContent?.intro}
                          isDesktop
                          // isTimeline
                        />
                      </Box>

                      {selectedPhaseTimelineContent?.posts &&
                        Object.keys(selectedPhaseTimelineContent?.posts)
                          .length > 0 && (
                          <>
                            <List
                              sx={{
                                mb: 0,
                                pb: 0,
                                pt: 0,
                                // overflowX: "scroll",
                                // height: access_token ? "88vh" : "30vh",
                              }}
                            >
                              {Object.entries(
                                selectedPhaseTimelineContent?.posts
                              ).map(([month, postsInMonth]) => (
                                <Box key={month}>
                                  <ListSubheader
                                    sx={{
                                      bgcolor: "background.paper",
                                      padding: 0,
                                      fontFamily: "inherit",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        height: "35px",
                                        width: "100%",
                                        backgroundColor:
                                          "rgba(245, 245, 245, 0.50)",
                                        boxShadow:
                                          "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontFamily: "inherit",
                                          fontSize: "15px",
                                          fontWeight: "600",
                                          color: "#3D3D3D",
                                          padding: "0px 10px",
                                        }}
                                      >
                                        {month}
                                      </Typography>
                                    </Box>
                                  </ListSubheader>

                                  {postsInMonth?.map((post, index) => (
                                    <Box
                                      key={index}
                                      sx={{
                                        padding: { xs: "15px 20px", sm: "0px" },
                                      }}
                                    >
                                      <HomepageItem
                                        item={post}
                                        isDesktop={
                                          window.innerWidth < 768 ? false : true
                                        }
                                      />
                                    </Box>
                                  ))}
                                </Box>
                              ))}
                            </List>
                          </>
                        )}
                    </Box>
                  ) : (
                    <Box sx={{ padding: "10px" }}>
                      {/* <Box>
                      <Typography
                        sx={{
                          fontFamily: "inherit",
                          fontSize: "18px",
                          color: "#3D3D3D",
                          margin: "5px 0px",
                          fontWeight: "600",
                        }}
                      >
                        {timelineContent?.video_timeline?.title}
                      </Typography>
                    </Box> */}
                      <Box sx={{ margin: "10px 0px" }}>
                        <HomepageItem
                          item={timelineContent?.video_timeline}
                          isDesktop
                          // isTimeline
                        />
                      </Box>
                      <Divider
                        sx={{
                          backgroundColor: "rgba(61, 61, 61, 0.20)",
                          width: "100%",
                          margin: "5px 0px",
                        }}
                      />
                      <Grid
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          marginTop: "10px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "inherit",
                            fontSize: "16px",
                            color: "rgba(61, 61, 61, 0.70)",
                          }}
                        >
                          Sort by:
                        </Typography>
                        <Box sx={{ marginLeft: "10px" }}>
                          <Select
                            value={selectedMonth}
                            onChange={handleChange}
                            IconComponent={ChevronDownExperiences}
                            displayEmpty={true}
                            renderValue={(value) =>
                              selectedMonth ? value : "Month"
                            }
                            sx={{
                              width: "100px",
                              opacity: 0.7,
                              fontFamily: "inherit",
                              "& .MuiOutlinedInput-input": {
                                padding: "3px 4px 3px 5px",
                                marginLeft: "4px",
                                fontFamily: "inherit",
                                display: "flex",
                                justifyContent: "center",
                                fontWeight: 500,
                                opacity: 0.7,
                              },
                            }}
                            MenuProps={{
                              style: {
                                maxHeight: "300px",
                                fontFamily: "inherit",
                              },
                            }}
                          >
                            {monthsTimelineFilter.map((x) => {
                              return (
                                <MenuItem
                                  value={x}
                                  key={x}
                                  sx={{
                                    color: "#3D3D3D",
                                    fontFamily: "inherit",
                                  }}
                                >
                                  {x}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Box>
                      </Grid>
                      {phaseMonthTimelineContentLoading ? (
                        <LoadingSpinner pageHeight={"100px"} size={30} />
                      ) : (
                        <>
                          <List
                            sx={{
                              mb: 0,
                              pb: 0,
                              pt: 0,
                            }}
                          >
                            {selectedMonth ? (
                              <>
                                {selectedPhaseMonthTimelineContent?.length >
                                0 ? (
                                  <Box>
                                    <ListSubheader
                                      sx={{
                                        bgcolor: "background.paper",
                                        padding: 0,
                                        fontFamily: "inherit",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          height: "35px",
                                          width: "100%",
                                          backgroundColor:
                                            "rgba(245, 245, 245, 0.50)",
                                          boxShadow:
                                            "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontFamily: "inherit",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                            color: "#3D3D3D",
                                            padding: "0px 10px",
                                          }}
                                        >
                                          {selectedMonth}
                                        </Typography>
                                      </Box>
                                    </ListSubheader>

                                    {selectedPhaseMonthTimelineContent?.map(
                                      (postInMonth, index) => (
                                        <Box
                                          key={index}
                                          sx={{
                                            padding: {
                                              xs: "15px 20px",
                                              sm: "0px",
                                            },
                                          }}
                                        >
                                          <HomepageItem
                                            item={postInMonth}
                                            isDesktop={
                                              window.innerWidth < 768
                                                ? false
                                                : true
                                            }
                                          />
                                        </Box>
                                      )
                                    )}
                                  </Box>
                                ) : (
                                  <>
                                    <Box
                                      sx={{
                                        marginTop: "10px",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#3D3D3D",
                                          fontFamily: "inherit",
                                          fontSize: "14px",
                                        }}
                                      >
                                        No available posts for this month
                                      </Typography>
                                    </Box>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {timelineContent?.posts ? (
                                  Object.entries(timelineContent?.posts).map(
                                    ([month, postsInMonth]) => (
                                      <Box key={month}>
                                        <ListSubheader
                                          sx={{
                                            bgcolor: "background.paper",
                                            padding: 0,
                                            fontFamily: "inherit",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              height: "35px",
                                              width: "100%",
                                              backgroundColor:
                                                "rgba(245, 245, 245, 0.50)",
                                              boxShadow:
                                                "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                                              display: "flex",
                                              alignItems: "center",
                                              marginTop: "20px",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontFamily: "inherit",
                                                fontSize: "15px",
                                                fontWeight: "600",
                                                color: "#3D3D3D",
                                                padding: "0px 10px",
                                              }}
                                            >
                                              {month}
                                            </Typography>
                                          </Box>
                                        </ListSubheader>

                                        {postsInMonth?.map((post, index) => (
                                          <Box
                                            key={index}
                                            sx={{
                                              padding: {
                                                xs: "15px 20px",
                                                sm: "0px",
                                              },
                                            }}
                                          >
                                            <HomepageItem
                                              item={post}
                                              isDesktop={
                                                window.innerWidth < 768
                                                  ? false
                                                  : true
                                              }
                                            />
                                          </Box>
                                        ))}
                                      </Box>
                                    )
                                  )
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </List>
                        </>
                      )}
                    </Box>
                  )}
                </>
              </Grid>
            )}
            <Grid
              item
              xs={watchedTimelineVideo ? 4 : 4}
              sx={{
                marginTop: watchedTimelineVideo ? "0px" : "15px",
                padding: watchedTimelineVideo ? "0px 30px" : "0px 30px",
              }}
              order={watchedTimelineVideo ? 2 : 3}
            >
              {watchedTimelineVideo && (
                <Grid>
                  <Box sx={{ margin: "10px 0px 20px 0px" }}>
                    <HomepageItem
                      item={timelineContent?.video_timeline}
                      isDesktop
                      isTimelineWatched
                    />
                  </Box>
                </Grid>
              )}
              {timelineArticles?.map((article, index) => {
                return (
                  <Grid key={index}>
                    <TimelineArticleItem article={article} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          {/* <Grid container gap={2}>
            
          </Grid> */}
        </>
        // <>
        //   <Grid container>
        //     <Grid xs={6} order={1}>
        //       hi1
        //     </Grid>
        //     <Grid xs={6} order={3}>
        //       hi2
        //     </Grid>
        //     <Grid xs={6} order={2}>
        //       hi3
        //     </Grid>
        //   </Grid>
        // </>
      )}
    </div>
  );
};

export default Timeline;
