import { createSlice } from "@reduxjs/toolkit";

import {
  experiencePayment,
  getAllExperiences,
  getAllOrders,
  getOneExperience,
  getOrderDetails,
} from "../../config/api";

const initialState = {
  isLoading: false,
  isPaymentLoading: false,
  error: "",
  paymentError: "",
  allExperiences: [],
  experienceDetails: {},
  selectedFeature: {},
  successfulPayment: {},
  allOrders: [],
  orderDetails: {},
};

const experiencesSlice = createSlice({
  name: "experiences",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.selectedPhaseTimelineContent = [];
      state.error = "";
    },
    // START PAYMENT LOADING
    startPaymentLoading(state) {
      state.isPaymentLoading = true;
      state.paymentError = "";
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action?.payload;
    },

    // HAS PAYMENT ERROR
    hasPaymentError(state, action) {
      state.isPaymentLoading = false;
      state.paymentError = action?.payload;
      state.isLoading = false;
    },

    fetchAllExperiencesSuccess: (state, action) => {
      state.isLoading = false;
      state.allExperiences = action.payload.data;
    },

    fetchOneExperienceSuccess: (state, action) => {
      state.isLoading = false;
      state.experienceDetails = action.payload.data;
      state.paymentError = "";
    },
    setSelectedFeature: (state, action) => {
      state.selectedFeature = action.payload;
    },
    purchaseOneExperienceSuccess: (state, action) => {
      state.isPaymentLoading = false;
      state.successfulPayment = action.payload.data;
      state.paymentError = "";
    },
    fetchAllOrdersSuccess: (state, action) => {
      state.isLoading = false;
      state.allOrders = action.payload.user;
    },
    fetchOrderDetailsSuccess: (state, action) => {
      state.isLoading = false;
      state.orderDetails = action.payload.order;
    },
  },
});
export const { setSelectedFeature, hasPaymentError } = experiencesSlice.actions;

//FECTH ALL EXPERIENCES
export function fetchAllExperiences() {
  return async (dispatch) => {
    dispatch(experiencesSlice.actions.startLoading());
    try {
      const res = await getAllExperiences();
      if (res.status === 200) {
        dispatch(experiencesSlice.actions.fetchAllExperiencesSuccess(res.data));
      } else {
        dispatch(experiencesSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(experiencesSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}
// FETCH ONE EXPERIENCE
export function fetchOneExperience(id) {
  return async (dispatch) => {
    dispatch(experiencesSlice.actions.startLoading());
    try {
      const res = await getOneExperience(id);
      if (res.status === 200) {
        dispatch(experiencesSlice.actions.fetchOneExperienceSuccess(res.data));
      } else {
        dispatch(experiencesSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(experiencesSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}

// EXPERIENCE PAYMENT
export function purchaseOneExperience(
  data,
  navigate,
  paymentMethodSelected,
  setBuyExperienceModal
) {
  return async (dispatch) => {
    dispatch(experiencesSlice.actions.startPaymentLoading());
    try {
      const res = await experiencePayment(data);
      if (res.status === 200) {
        dispatch(
          experiencesSlice.actions.purchaseOneExperienceSuccess(res.data)
        );
        if (
          paymentMethodSelected?.method === "stripe" ||
          paymentMethodSelected?.method === "wallet"
        ) {
          navigate("/order-success");
        } else {
          if (window.innerWidth < 768) {
            navigate(-1);
          }
          window.location.href = res?.data?.charge?.hosted_url;
          setBuyExperienceModal(false);
        }
      } else {
        dispatch(experiencesSlice.actions.hasPaymentError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(experiencesSlice.actions.hasPaymentError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}

//FECTH ALL ORDERS
export function fetchAllOrders() {
  return async (dispatch) => {
    dispatch(experiencesSlice.actions.startLoading());
    try {
      const res = await getAllOrders();
      if (res.status === 200) {
        dispatch(experiencesSlice.actions.fetchAllOrdersSuccess(res.data));
      } else {
        dispatch(experiencesSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(experiencesSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}

//FECTH ORDER DETAILS
export function fetchOrderDetails(id) {
  return async (dispatch) => {
    dispatch(experiencesSlice.actions.startLoading());
    try {
      const res = await getOrderDetails(id);
      if (res.status === 200) {
        dispatch(experiencesSlice.actions.fetchOrderDetailsSuccess(res.data));
      } else {
        dispatch(experiencesSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(experiencesSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}
export default experiencesSlice.reducer;
