import React from "react";

const ChevronDownExperiences = () => {
  return (
    <div
      style={{
        position: "absolute",
        right: window.innerWidth < 760 ? 12 : 20,
        top: window.innerWidth < 760 ? 4 : 3,
        opacity: 0.9,
      }}
    >
      <svg
        width={window.innerWidth < 760 ? "8" : "10"}
        height={window.innerWidth < 760 ? "8" : "10"}
        viewBox="0 0 7 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.33054 0.504522L0.330537 0.504521C0.26979 0.504712 0.210246 0.521466 0.158312 0.55298C0.106379 0.584495 0.0640234 0.629576 0.0358056 0.683372C0.00758785 0.737168 -0.00542457 0.797641 -0.00183017 0.858282C0.00176422 0.918923 0.021829 0.977435 0.0562042 1.02752L3.0562 5.36086C3.18054 5.54052 3.47987 5.54052 3.60454 5.36086L6.60454 1.02752C6.63926 0.97754 6.65963 0.918999 6.66342 0.858257C6.66721 0.797515 6.65428 0.736896 6.62603 0.682986C6.59779 0.629076 6.55531 0.583937 6.50322 0.552474C6.45112 0.52101 6.3914 0.504426 6.33054 0.504522Z"
          fill="#3D3D3D"
          fillOpacity="0.7"
        />
      </svg>
    </div>
  );
};

export default ChevronDownExperiences;
