import React from "react";

const ChevronDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
    >
      <path
        d="M6.99997 2.16669L0.999971 2.16669C0.939224 2.16688 0.879679 2.18364 0.827746 2.21515C0.775812 2.24667 0.733457 2.29175 0.705239 2.34554C0.677021 2.39934 0.664009 2.45981 0.667603 2.52045C0.671198 2.58109 0.691262 2.63961 0.725638 2.68969L3.72564 7.02303C3.84997 7.20269 4.1493 7.20269 4.27397 7.02303L7.27397 2.68969C7.3087 2.63971 7.32906 2.58117 7.33285 2.52043C7.33664 2.45969 7.32371 2.39907 7.29547 2.34516C7.26722 2.29125 7.22475 2.24611 7.17265 2.21464C7.12055 2.18318 7.06083 2.1666 6.99997 2.16669Z"
        fill="#3D3D3D"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default ChevronDown;
