import React from "react";
import { Grid, Typography } from "@mui/material";

const TimelineArticleItem = ({ article }) => {
  return (
    <Grid container sx={{ margin: "10px 0px" }}>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: "21px",
            fontWeight: "600",
            fontFamily: "inherit",
            color: "#00020E",
          }}
        >
          {article?.title}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ margin: "15px 0px" }}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            fontFamily: "inherit",
            color: "#3D3D3DB3",
            opacity: 0.7,
          }}
        >
          {article?.description}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "inherit",
            color: "rgba(61, 61, 61, 0.60)",
          }}
        >
          By
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "inherit",
            color: "#3D3D3D",
            marginLeft: "5px",
          }}
        >
          {article?.added_by}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TimelineArticleItem;
