import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
//Icons
import { ChevronLeft } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { fetchAboutUsDetails } from "../redux/slices/homeSlice";
import DOMPurify from "dompurify";
import LoadingSpinner from "../components/loadingSpinner/LoadingSpinner";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { sendMessageForm } from "../config/api";
//Maps
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  LoadScript,
} from "@react-google-maps/api";
import GoogleMapsComponent from "../components/gogleMaps/GoogleMapsComponent";

const AboutUs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { aboutUsDetails, isLoading } = useSelector((state) => state.home);
  const [responseMessage, setResponseMessage] = useState("");

  const sanitizedHtmlDescirption = DOMPurify.sanitize(
    aboutUsDetails?.description
  );
  const sanitizedHtmlShortDescirption = DOMPurify.sanitize(
    aboutUsDetails?.short_description
  );
  const sanitizedHtmlVision = DOMPurify.sanitize(
    aboutUsDetails?.vision_description
  );
  const sanitizedHtmlMission = DOMPurify.sanitize(
    aboutUsDetails?.mission_description
  );
  const validationSchema = Yup.object({
    fname: Yup.string().required("First Name is required"),
    lname: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  const inputStyle = {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      width: "100%",
      fontFamily: "inherit",
      borderRadius: "5px",
      border: "0.5px solid rgba(61, 61, 61, 0.30);",
      backgroundColor:
        window?.innerWidth < 768
          ? "transparent"
          : "var(--white-white-20, #F8F9FA)",
    },
    ".MuiTextField-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      padding: 0,
      borderColor: "white",
      borderWidth: "1px !important",
    },
    ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      padding: 0,
      borderColor: "white",
    },
    ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d32f2f",
    },
    ".MuiFormHelperText-root.Mui-error": {
      fontFamily: "inherit",
    },
    ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },

    "& .MuiOutlinedInput-input": {
      color: "rgba(61, 61, 61, 0.60)",
      padding: "10px",
      width: "100%",
      fontFamily: "inherit",
      fontSize: "12px",
      fontWeight: "600",
    },
  };
  const submitForm = async (values, { resetForm }) => {
    let obj = {
      first_name: values?.fname,
      last_name: values?.lname,
      email: values?.email,
      message: values?.message,
    };

    await sendMessageForm(obj).then((res) => {
      if (res?.status === 200) {
        setResponseMessage(res.data.message);
        resetForm();
      } else {
        setResponseMessage(res?.data?.error?.message);
        console.log("error", res?.data?.message);
      }
      console.log("error", res?.data?.message);
    });
  };
  useEffect(() => {
    dispatch(fetchAboutUsDetails());
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : window.innerWidth < 768 ? (
        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {window.innerWidth < 768 && (
                <Grid
                  item
                  sx={{
                    position: "absolute",
                    left: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/")}
                >
                  <ChevronLeft sx={{ fontSize: "40px" }} />
                </Grid>
              )}

              <Grid item>
                <Typography
                  sx={{
                    fontFamily: "inherit",
                    fontSize: "28px",
                    fontWeight: "600",
                  }}
                >
                  About Us
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container sx={{ padding: "10px 15px" }}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    height: "330px",
                    borderRadius: "5px",
                    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 20.36%, rgba(0, 0, 0, 0.00) 45.81%), url(${
                      aboutUsDetails?.image ? aboutUsDetails?.image : ""
                    }),  #ECECEC`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    position: "relative",
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <Typography
                  sx={{
                    fontFamily: "inherit",
                    color: "#000000",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  {aboutUsDetails?.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "inherit",
                    color: "rgba(0, 0, 0, 0.50)",
                    fontSize: "12px",
                    marginBottom: "10px",
                    marginTop: "-3px",
                    "& p": {
                      margin: "0 !important",
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: sanitizedHtmlShortDescirption,
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "inherit",
                    color: "rgba(61, 61, 61, 0.90)",
                    fontSize: "13px",
                    fontWeight: "600",
                    "& p": {
                      margin: "0 !important",
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: sanitizedHtmlDescirption }}
                />
              </Grid>

              <Grid item xs={12} sx={{ marginTop: "20px" }}>
                <Grid
                  container
                  columnGap={1}
                  sx={{ display: "flex", alignItems: "flex-start" }}
                >
                  <Grid
                    item
                    xs={3}
                    sx={{
                      minWidth: "80px",
                      width: "80px",
                      maxWidth: "80px",
                      height: "80px",
                      borderRadius: "5px",
                      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 20.36%, rgba(0, 0, 0, 0.00) 45.81%), url(${
                        aboutUsDetails?.vision_image
                          ? aboutUsDetails?.vision_image
                          : ""
                      }),  #ECECEC`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      position: "relative",
                    }}
                  />
                  <Grid item xs={8}>
                    <Typography
                      sx={{
                        color: "rgba(61, 61, 61, 0.80)",
                        fontSize: "16px",
                        fontFamily: "inherit",
                        marginBottom: "5px",
                        fontWeight: "600",
                      }}
                    >
                      {aboutUsDetails?.vision_title}
                    </Typography>
                    <Typography
                      sx={{
                        color: "rgba(61, 61, 61, 0.60)",
                        fontSize: "12px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                        "& p": {
                          margin: "0 !important",
                        },
                      }}
                      dangerouslySetInnerHTML={{ __html: sanitizedHtmlVision }}
                    />
                  </Grid>
                  <Divider
                    sx={{
                      backgroundColor: "rgba(0, 0, 0, 0.10)",
                      width: "100%",
                      margin: "10px 0px 10px 0px",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  columnGap={1}
                  sx={{ display: "flex", alignItems: "flex-start" }}
                >
                  <Grid
                    item
                    xs={2}
                    sx={{
                      minWidth: "80px",
                      width: "80px",
                      maxWidth: "80px",
                      height: "80px",
                      borderRadius: "5px",
                      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 20.36%, rgba(0, 0, 0, 0.00) 45.81%), url(${
                        aboutUsDetails?.mission_image
                          ? aboutUsDetails?.mission_image
                          : ""
                      }),  #ECECEC`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      position: "relative",
                    }}
                  />
                  <Grid item xs={8}>
                    <Typography
                      sx={{
                        color: "rgba(61, 61, 61, 0.80)",
                        fontSize: "16px",
                        fontFamily: "inherit",
                        marginBottom: "5px",
                        fontWeight: "600",
                      }}
                    >
                      {aboutUsDetails?.mission_title}
                    </Typography>
                    <Typography
                      sx={{
                        color: "rgba(61, 61, 61, 0.60)",
                        fontSize: "12px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                        "& p": {
                          margin: "0 !important",
                        },
                      }}
                      dangerouslySetInnerHTML={{ __html: sanitizedHtmlMission }}
                    />
                  </Grid>
                  <Divider
                    sx={{
                      backgroundColor: "rgba(0, 0, 0, 0.10)",
                      width: "100%",
                      margin: "10px 0px 10px 0px",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid sx={{
                width:"100%"
              }}>
                <Box sx={{
                width:"100%"
              }}>
                  <iframe style={{
                    width:"100%", 
                    height:"500px",
                    outline:0,
                    boxShadow:"none",
                    border:"solid 1px lightgrey"
                    }}  src="https://thesetmap.sync.com.cy/"></iframe>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ height: "360px", marginTop: "20px" }}>
                {aboutUsDetails?.api_key && (
                  <GoogleMapsComponent
                    lat={aboutUsDetails?.latitude}
                    long={aboutUsDetails?.longitude}
                    api_key={aboutUsDetails?.api_key}
                  />
                )}
              </Grid>
              <Grid xs={12} sx={{ marginTop: "17px" }}>
                <Formik
                  initialValues={{
                    fname: "",
                    lname: "",
                    email: "",
                    message: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikBag) =>
                    submitForm(values, formikBag)
                  }
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    touched,
                    errors,
                    isSubmitting,
                  }) => (
                    <Form>
                      <Grid
                        container
                        sx={{
                          padding: { sm: "20px", md: "50px" },
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "18px",
                              fontWeight: "600",
                              fontFamily: "inherit",
                            }}
                          >
                            Let’s Talk
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              color: "#3D3D3D",
                              fontSize: "12px",
                              fontWeight: "400",
                              fontFamily: "inherit",
                            }}
                          >
                            Fill up the form our team will get back to you
                            within 24 Hours
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          gap={{ sm: 0, md: 1 }}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "15px",
                          }}
                        >
                          <Grid item xs={5.7}>
                            <TextField
                              name="fname"
                              placeholder="Enter your First Name"
                              sx={inputStyle}
                              fullWidth
                              value={values.fname}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.fname && Boolean(errors.fname)}
                            />
                          </Grid>
                          <Grid item xs={5.7}>
                            <TextField
                              name="lname"
                              placeholder="Enter your Last Name"
                              sx={inputStyle}
                              fullWidth
                              value={values.lname}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.lname && Boolean(errors.lname)}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: "15px" }}>
                          <TextField
                            name="email"
                            placeholder="Enter your Email Address"
                            sx={inputStyle}
                            fullWidth
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && Boolean(errors.email)}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: "15px" }}>
                          <TextField
                            name="message"
                            placeholder="Enter your Messages"
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                padding: 0,
                                borderColor: "white",
                                borderWidth: "1px !important",
                              },
                              ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  padding: 0,
                                  borderColor: "white",
                                },
                              ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#d32f2f",
                                },
                              ".MuiFormHelperText-root.Mui-error": {
                                fontFamily: "inherit",
                              },
                              ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "white",
                                },

                              "& .MuiOutlinedInput-root": {
                                width: "100%",
                                fontFamily: "inherit",
                                borderRadius: "5px",
                                border: "0.5px solid rgba(61, 61, 61, 0.30);",
                              },
                              "& .MuiOutlinedInput-input": {
                                color: "rgba(61, 61, 61, 0.60)",
                                width: "100%",
                                fontFamily: "inherit",
                                fontSize: { xs: "12px", sm: "14px" },
                                fontWeight: "600",
                              },
                            }}
                            multiline
                            rows={4}
                            fullWidth
                            value={values.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.message && Boolean(errors.message)}
                          />
                        </Grid>
                        {responseMessage && (
                          <Grid item sm={12}>
                            <Typography
                              sx={{
                                fontFamily: "inherit",
                                color: "#3D3D3D",
                                fontSize: "10px",
                              }}
                            >
                              {responseMessage}
                            </Typography>
                          </Grid>
                        )}

                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              boxShadow: "none !important",
                              backgroundColor: "#EBEADF !important",
                              borderRadius: "5px",
                              textTransform: "none",
                              marginTop: "10px",
                              height: "42px",
                              width: "100%",
                              fontSize: "12px",
                              fontWeight: "600",
                              fontFamily: "inherit",
                              color: "#3D3D3D !important",
                              "&:hover": {
                                backgroundColor: "#EBEADF !important",
                              },
                              "&:disabled": {
                                opacity: 0.5,
                              },
                            }}
                            disabled={isSubmitting}
                          >
                            Send
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container sx={{ padding: "20px 50px" }}>
          <Grid
            item
            sm={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                color: "#183B56",
                fontWeight: "600",
                fontFamily: "inherit",
                fontSize: { sm: "26px", md: "35px", lg: "50px" },
              }}
            >
              {aboutUsDetails?.title}
            </Typography>

            <Box
              sx={{
                width: { sm: "350px", md: "500px", lg: "700px" },
                height: { sm: "300px", md: "400px", lg: "500px" },
                borderRadius: "8px",
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 20.36%, rgba(0, 0, 0, 0.00) 45.81%), url(${
                  aboutUsDetails?.image ? aboutUsDetails?.image : ""
                }),  #ECECEC`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "relative",
                marginTop: "30px",
              }}
            />
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  color: "rgba(61, 61, 61, 0.60)",
                  fontSize: "16px",
                  margin: "10px 0px 0px 0px",
                  textAlign: "center",
                  maxWidth: { sm: "350px", md: "500px", lg: "700px" },
                  "& p": {
                    margin: "0 !important",
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitizedHtmlShortDescirption,
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  color: "rgba(61, 61, 61, 0.90)",
                  fontSize: "19px",
                  textAlign: "center",
                  margin: "0px 0px 10px 0px",
                  maxWidth: { sm: "350px", md: "500px", lg: "700px" },
                  "& p": {
                    margin: "0 !important",
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitizedHtmlDescirption,
                }}
              />
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Grid container sx={{ marginTop: "90px" }} gap={3}>
              <Grid
                item
                sm={6}
                sx={{
                  position: "relative",
                  minHeight: { sm: "300px", md: "400px", lg: "600px" },
                  height: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xl: "center" },
                }}
              >
                <Grid
                  sx={{
                    width: { sm: "150px", md: "250px", lg: "355px" },
                    height: { sm: "200px", md: "300px", lg: "430px" },
                    border: "10px solid #FFFFFF",
                    backgroundColor: "#FFFFFF",
                    position: "absolute",
                    top: 0,
                    // left: 0,
                    zIndex: 10,
                    borderRadius: "8px",
                  }}
                >
                  <Grid
                    sx={{
                      width: "100%",
                      height: "100%",
                      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 20.36%, rgba(0, 0, 0, 0.00) 45.81%), url(${
                        aboutUsDetails?.vision_image
                          ? aboutUsDetails?.vision_image
                          : ""
                      }),  #ECECEC`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      borderRadius: "8px",
                    }}
                  />
                </Grid>

                <Grid
                  sx={{
                    width: { sm: "150px", md: "250px", lg: "362px" },
                    height: { sm: "200px", md: "300px", lg: "430px" },
                    borderRadius: "8px",
                    border: "3px solid #FFFFFF",
                    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 20.36%, rgba(0, 0, 0, 0.00) 45.81%), url(${
                      aboutUsDetails?.mission_image
                        ? aboutUsDetails?.mission_image
                        : ""
                    }),  #ECECEC`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    position: "absolute",
                    bottom: 0,
                    right: 20,
                  }}
                />
              </Grid>
              <Grid item sm={5}>
                <Grid sx={{ marginTop: { md: "10px", lg: "90px" } }}>
                  <Typography
                    sx={{
                      color: "#3D3D3D",
                      fontSize: { sm: "26px", md: "30px", lg: "38px" },
                      fontFamily: "inherit",
                      marginBottom: { sm: "5px", md: "15px" },
                      fontWeight: "700",
                    }}
                  >
                    {aboutUsDetails?.vision_title}
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(61, 61, 61, 0.60)",
                      fontSize: { sm: "18px", md: "20px", lg: "22px" },
                      fontFamily: "inherit",
                      fontWeight: "500",
                      "& p": {
                        margin: "0 !important",
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: sanitizedHtmlVision }}
                  />
                </Grid>
                <Grid
                  sx={{ marginTop: { sm: "10px", md: "20px", lg: "100px" } }}
                >
                  <Typography
                    sx={{
                      color: "#3D3D3D",
                      fontSize: { sm: "26px", md: "30px", lg: "38px" },
                      fontFamily: "inherit",
                      marginBottom: { sm: "5px", md: "15px" },
                      fontWeight: "700",
                    }}
                  >
                    {aboutUsDetails?.mission_title}
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(61, 61, 61, 0.60)",
                      fontSize: { sm: "18px", md: "20px", lg: "22px" },
                      fontFamily: "inherit",
                      fontWeight: "500",
                      "& p": {
                        margin: "0 !important",
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: sanitizedHtmlMission }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{
                width:"100%",
                marginTop:"30px"
              }}>
                <Box sx={{
                width:"100%"
              }}>
                  <iframe style={{
                    width:"100%",
                    marginLeft:"auto", 
                    marginRight:"auto", 
                    height:"800px",
                    outline:0,
                    boxShadow:"none",
                    border:"solid 1px lightgrey"
                    }}  src="https://thesetmap.sync.com.cy/"></iframe>
                </Box>
              </Grid>
          <Grid item sm={12} sx={{ marginTop: "70px", height: "580px" }}>
            <Grid container sx={{ height: "100%" }}>
              <Grid item sm={6}>
                {aboutUsDetails?.api_key && (
                  <GoogleMapsComponent
                    lat={aboutUsDetails?.latitude}
                    long={aboutUsDetails?.longitude}
                    api_key={aboutUsDetails?.api_key}
                  />
                )}
              </Grid>
              <Grid
                item
                sm={6}
                sx={{ backgroundColor: "#F3F2EC", height: "100%" }}
              >
                <Formik
                  initialValues={{
                    fname: "",
                    lname: "",
                    email: "",
                    message: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikBag) =>
                    submitForm(values, formikBag)
                  }
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    touched,
                    errors,
                    isSubmitting,
                  }) => (
                    <Form>
                      <Grid
                        container
                        sx={{
                          padding: { sm: "20px", md: "50px" },
                        }}
                      >
                        <Grid item sm={12}>
                          <Typography
                            sx={{
                              color: "#3D3D3D",
                              fontSize: "38px",
                              fontWeight: "700",
                              fontFamily: "inherit",
                            }}
                          >
                            Let’s Talk
                          </Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Typography
                            sx={{
                              color: "#3D3D3D",
                              fontSize: "12px",
                              fontWeight: "400",
                              fontFamily: "inherit",
                            }}
                          >
                            Fill up the form our team will get back to you
                            within 24 Hours
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          gap={{ sm: 0, md: 1 }}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "15px",
                          }}
                        >
                          <Grid item sm={5.7} sx={{ flexDirection: "column" }}>
                            <Box
                              sx={{
                                marginBottom: "10px",
                                display: "flex",
                                alignItems: "baseline",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#3D3D3D",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                }}
                              >
                                First Name
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#d32f2f",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                }}
                              >
                                *
                              </Typography>
                            </Box>

                            <TextField
                              name="fname"
                              placeholder="Enter your First Name"
                              sx={inputStyle}
                              fullWidth
                              value={values.fname}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.fname && Boolean(errors.fname)}
                            />
                          </Grid>
                          <Grid item sm={5.7} sx={{ flexDirection: "column" }}>
                            <Box
                              sx={{
                                marginBottom: "10px",
                                display: "flex",
                                alignItems: "baseline",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#3D3D3D",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                }}
                              >
                                Last Name
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#d32f2f",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                }}
                              >
                                *
                              </Typography>
                            </Box>
                            <TextField
                              name="lname"
                              placeholder="Enter your Last Name"
                              sx={inputStyle}
                              fullWidth
                              value={values.lname}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.lname && Boolean(errors.lname)}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          sm={12}
                          sx={{ flexDirection: "column", marginTop: "15px" }}
                        >
                          <Box
                            sx={{
                              marginBottom: "10px",
                              display: "flex",
                              alignItems: "baseline",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#3D3D3D",
                                fontSize: "13px",
                                fontWeight: "500",
                              }}
                            >
                              Email Address
                            </Typography>
                            <Typography
                              sx={{
                                color: "#d32f2f",
                                fontSize: "13px",
                                fontWeight: "500",
                              }}
                            >
                              *
                            </Typography>
                          </Box>
                          <TextField
                            name="email"
                            placeholder="Enter your Email Address"
                            sx={inputStyle}
                            fullWidth
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && Boolean(errors.email)}
                          />
                        </Grid>
                        <Grid
                          item
                          sm={12}
                          sx={{ flexDirection: "column", marginTop: "15px" }}
                        >
                          <Box
                            sx={{
                              marginBottom: "10px",
                              display: "flex",
                              alignItems: "baseline",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#3D3D3D",
                                fontSize: "13px",
                                fontWeight: "500",
                              }}
                            >
                              Messages
                            </Typography>
                            <Typography
                              sx={{
                                color: "#d32f2f",
                                fontSize: "13px",
                                fontWeight: "500",
                              }}
                            >
                              *
                            </Typography>
                          </Box>
                          <TextField
                            name="message"
                            placeholder="Enter your Messages"
                            sx={{
                              backgroundColor: "var(--white-white-20, #F8F9FA)",
                              "& .MuiOutlinedInput-notchedOutline": {
                                padding: 0,
                                borderColor: "white",
                                borderWidth: "1px !important",
                              },
                              ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  padding: 0,
                                  borderColor: "white",
                                },
                              ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#d32f2f",
                                },
                              ".MuiFormHelperText-root.Mui-error": {
                                fontFamily: "inherit",
                              },
                              ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "white",
                                },

                              "& .MuiOutlinedInput-root": {
                                width: "100%",
                                fontFamily: "inherit",
                                borderRadius: "5px",
                                border: "0.5px solid rgba(61, 61, 61, 0.30);",
                              },
                              "& .MuiOutlinedInput-input": {
                                color: "#3d3d3d",
                                width: "100%",
                                fontFamily: "inherit",
                                fontSize: { xs: "12px", sm: "14px" },
                              },
                            }}
                            multiline
                            rows={4}
                            fullWidth
                            value={values.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.message && Boolean(errors.message)}
                          />
                        </Grid>
                        {responseMessage && (
                          <Grid item sm={12}>
                            <Typography
                              sx={{
                                fontFamily: "inherit",
                                color: "#3D3D3D",
                                fontSize: "12px",
                              }}
                            >
                              {responseMessage}
                            </Typography>
                          </Grid>
                        )}

                        <Grid
                          item
                          sm={12}
                          sx={{ marginTop: responseMessage ? "10px" : "15px" }}
                        >
                          <Grid item sm={12}>
                            <Typography
                              sx={{
                                color: "#d32f2f",
                                fontSize: "13px",
                                fontWeight: "500",
                                textAlign: "right",
                              }}
                            >
                              * Required Fields
                            </Typography>
                          </Grid>
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              boxShadow: "none !important",
                              backgroundColor: "#F3F2EC !important",
                              border: "1px solid rgba(61, 61, 61, 0.30)",
                              borderRadius: "8px",
                              textTransform: "none",

                              height: "40px",
                              width: "145px",
                              fontSize: "12px",
                              fontWeight: "600",
                              fontFamily: "inherit",
                              color: "#3D3D3D !important",
                              "&:hover": {
                                backgroundColor: "#F3F2EC !important",
                              },
                              "&:disabled": {
                                opacity: 0.5,
                              },
                            }}
                            disabled={isSubmitting}
                          >
                            Send Message
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AboutUs;
