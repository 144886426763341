import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

//Icons
import { ChevronLeft } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Redux
import { purchaseOneExperience } from "../../redux/slices/experiencesSlice";
import { useDispatch, useSelector } from "react-redux";

//Stripe
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

//Wallet
import { ethers } from "ethers";
import GiftIcon from "../../assets/icons/GiftIcon";

const OrderSummaryPage = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedFeature, isLoading, isPaymentLoading, paymentError } =
    useSelector((state) => state.experiences);
  const { access_token } = useSelector((state) => state.auth);

  const [numberOfPersons, setNumberOfPersons] = useState(1);
  const [cardNumberError, setCardNumberError] = useState(false);
  const [expiryElementError, setExpiryElementError] = useState(false);
  const [cvcElementError, setCvcElementError] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [successNFTPayment, setSuccessNFTPayment] = useState(false);
  const [nftPaymentMessage, setNftPaymentMessage] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const handleChange = (event) => {
    setNumberOfPersons(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingButton(true);
    if (paymentMethodSelected?.method === "stripe") {
      if (!stripe || !elements) {
        return;
      }

      const cardNumberElement = elements.getElement(CardNumberElement);

      const { token, error } = await stripe.createToken(cardNumberElement);

      if (error) {
        setError(error.message);
        setLoadingButton(false);
      } else {
        // Send the token to your server for payment processing
        console.log("Token:", token);
        handlePaymentProcess(token);
      }
    } else if (paymentMethodSelected?.method === "wallet") {
      console.log("Wallet connet");
      requestAccount();
    } else {
      handlePaymentProcess();
    }
  };

  async function requestAccount() {
    console.log("Requesting account...");

    if (window.ethereum) {
      console.log("detected");

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setWalletAddress(accounts[0]);
        console.log("account", accounts[0]);
        if (accounts[0]) {
          const walletAddress = accounts[0];
          const tokenId = 944; // Replace with the specific tokenId you want to check
          checkNFTOwnership(walletAddress, tokenId);
          handlePaymentProcess(null, walletAddress);
        }
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      alert("Meta Mask not detected");
    }
  }

  const nftContractAddress = "0x19391CA492Fc5994f13E0031Ed5B5D9134a35d14";

  const nftContractTokenId = 944;

  async function checkNFTOwnership(walletAddress) {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // Create a contract instance with the ERC-721 ABI
      const nftContract = new ethers.Contract(
        nftContractAddress,
        ["function ownerOf(uint256 tokenId) view returns (address)"],
        provider
      );
      console.log("walletAddress", walletAddress);
      // Call the ownerOf function to get the owner of the specified token ID
      const owner = await nftContract.ownerOf(nftContractTokenId);
      console.log("owner", owner);
      if (owner?.toLowerCase() === walletAddress?.toLowerCase()) {
        console.log(
          `The user owns the NFT with token ID ${nftContractTokenId}`
        );
        setNftPaymentMessage(
          `You are the owner of one of "Society of The Wine Grower" NFT Collection. Hidden Gems and Exclusive Experiences will be Soon Enabled for You!`
        );
        setSuccessNFTPayment(true);
      } else {
        console.log(
          `The user does not own the NFT with token ID ${nftContractTokenId}`
        );
        setNftPaymentMessage(
          "You don't own one of our NFT collection , please try again."
        );

        setSuccessNFTPayment(true);
      }
    } catch (error) {
      console.error("Error checking NFT ownership:", error);
    }
  }
  const handlePaymentProcess = (token) => {
    let obj = {
      nbOfSeats: numberOfPersons,
      slug: id,
      amount:
        numberOfPersons *
        (selectedFeature?.discountPrice
          ? selectedFeature?.discountPrice
          : selectedFeature?.price),
      date: moment(selectedFeature?.date).format("YYYY-MM-DD"),
      featureId: selectedFeature?.id,
      token: token?.id,
      payment_method: paymentMethodSelected?.method,
    };

    dispatch(purchaseOneExperience(obj, navigate, paymentMethodSelected));
    setLoadingButton(false);
  };

  const handleSelectPaymentOption = (method) => {
    setPaymentMethodSelected(method);
  };

  useEffect(() => {
    if (elements) {
      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);

      if (cardNumberElement?._implementation?._invalid) {
        setCardNumberError(true);
      } else {
        setCardNumberError(false);
      }
      if (cardExpiryElement?._implementation?._invalid) {
        setExpiryElementError(true);
      } else {
        setExpiryElementError(false);
      }
      if (cardCvcElement?._implementation?._invalid) {
        setCvcElementError(true);
      } else {
        setCvcElementError(false);
      }
    }
  }, [error]);

  useEffect(() => {
    if (!access_token) {
      navigate("/");
    }
  }, [access_token]);

  return (
    <Grid container sx={{ padding: "10px 15px" }}>
      <Grid
        item
        xs={12}
        onClick={() => navigate(-1)}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          cursor: "pointer",
          padding: "2px 0px",
          color: "#3D3D3D",
        }}
      >
        <ChevronLeft
          sx={{ fontSize: "28px", marginLeft: "-5px", color: "#3D3D3D" }}
        />
        <Typography
          sx={{
            fontFamily: "inherit",
            color: "#3D3D3D",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Order Summary
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ height: "80vh" }}>
        <Grid container>
          <Grid item xs={12} sx={{ padding: "0px 5px" }}>
            <Grid container sx={{ marginTop: "20px" }}>
              <Grid item xs={10}>
                <Typography
                  sx={{
                    fontFamily: "inherit",
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#3D3D3D",
                  }}
                >
                  {selectedFeature?.title}
                </Typography>
                <Box sx={{ margin: "10px 0px" }}>
                  <Typography
                    sx={{
                      fontFamily: "inherit",
                      color: "rgba(61, 61, 61, 0.70)",
                      fontSize: "14px",
                    }}
                  >
                    {selectedFeature?.date}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "inherit",
                      fontSize: "16px",
                      color: "rgba(61, 61, 61, 0.80)",
                    }}
                  >
                    Number of persons:
                  </Typography>
                  <Box sx={{ marginLeft: "10px" }}>
                    <Select
                      value={numberOfPersons}
                      onChange={handleChange}
                      IconComponent={ExpandMoreIcon}
                      sx={{
                        width: "55px",
                        fontFamily: "inherit",
                        "& .MuiOutlinedInput-input": {
                          padding: "3px 0px 3px 5px",
                        },
                      }}
                      MenuProps={{
                        style: {
                          maxHeight: "300px",
                        },
                      }}
                    >
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => {
                        return (
                          <MenuItem
                            value={x}
                            key={x}
                            sx={{ color: "#3D3D3D", fontFamily: "inherit" }}
                          >
                            {x}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{
                    fontFamily: "inherit",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#FF001F",
                  }}
                >
                  $
                  {numberOfPersons *
                    (selectedFeature?.discountPrice
                      ? selectedFeature?.discountPrice
                      : selectedFeature?.price)}
                </Typography>
              </Grid>
            </Grid>
            <Divider
              sx={{
                backgroundColor: "#E6E7E8",
                width: "100%",
                margin: "10px 0px",
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "20px", padding: "0px 5px" }}>
            <Typography
              sx={{
                fontFamily: "inherit",
                color: "#3D3D3D",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Payment method
            </Typography>
            {[
              { method: "stripe", name: "Credit Card" },
              { method: "crypto", name: "Crypto" },
              { method: "wallet", name: "Connect Wallet" },
            ].map((method, index) => {
              return (
                <Grid key={index}>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      marginTop: "5px",
                      cursor: "pointer",
                      borderRadius: "5px",
                      margin: "20px 0px",
                    }}
                    onClick={() => handleSelectPaymentOption(method)}
                  >
                    <Grid item xs={1}>
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "50%",
                          border: "2px solid #0070CC",
                          width: "16px",
                          height: "16px",
                          backgroundColor: "#0070CC",
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            background:
                              paymentMethodSelected?.name === method?.name
                                ? "#0070CC"
                                : "#FFFFFF",
                            border: "2px solid white",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "12px",
                            height: "12px",
                          }}
                        ></Box>
                      </Box>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography
                        sx={{
                          fontFamily: "inherit",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        {method?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          {paymentMethodSelected?.method === "stripe" && (
            <>
              <Grid item xs={12} sx={{ marginTop: "10px", padding: "0px 5px" }}>
                <Typography
                  sx={{
                    fontFamily: "inherit",
                    color: "#3D3D3D",
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                >
                  Enter your card details
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "20px", padding: "0px 5px" }}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          border: cardNumberError
                            ? "1px solid #9e2146"
                            : "1px solid #ced4da",
                          borderRadius: "4px",
                          padding: "10px",
                        }}
                      >
                        <CardNumberElement
                          options={{
                            showIcon: true,
                            placeholder: "Card Number",
                            style: {
                              base: {
                                fontSize: "14px",
                                color: "rgba(61, 61, 61, 0.70)",
                                "::placeholder": {
                                  color: "rgba(61, 61, 61, 0.20)",
                                },
                              },
                              invalid: {
                                color: "#9e2146",
                              },
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          border: expiryElementError
                            ? "1px solid #9e2146"
                            : "1px solid #ced4da",
                          borderRadius: "4px",
                          padding: "10px",
                        }}
                      >
                        <CardExpiryElement
                          options={{
                            placeholder: "Expiry Date",
                            style: {
                              base: {
                                fontSize: "14px",
                                color: "rgba(61, 61, 61, 0.70)",
                                "::placeholder": {
                                  color: "rgba(61, 61, 61, 0.20)",
                                },
                              },
                              invalid: {
                                color: "#9e2146",
                              },
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          border: cvcElementError
                            ? "1px solid #9e2146"
                            : "1px solid #ced4da",
                          borderRadius: "4px",
                          padding: "10px",
                        }}
                      >
                        <CardCvcElement
                          options={{
                            placeholder: "Enter CVC",

                            style: {
                              base: {
                                fontSize: "14px",
                                color: "rgba(61, 61, 61, 0.70)",

                                "::placeholder": {
                                  color: "rgba(61, 61, 61, 0.20)",
                                },
                              },
                              invalid: {
                                color: "#9e2146",
                              },
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    {error && (
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontFamily: "inherit",
                            fontSize: "12px",
                            color: "#FF001F",
                          }}
                        >
                          {error}
                        </Typography>
                      </Grid>
                    )}
                    {paymentError && (
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontFamily: "inherit",
                            fontSize: "12px",
                            color: "#FF001F",
                          }}
                        >
                          {paymentError}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </form>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
          variant="contained"
          type="submit"
          disabled={
            !stripe ||
            isPaymentLoading ||
            loadingButton ||
            !!paymentMethodSelected?.method === false
          }
          sx={{
            backgroundColor: "#0060FF !important",
            borderRadius: "5px",
            textTransform: "none",
            marginTop: "10px",
            height: "40px",
            width: "100%",
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "inherit",
            color: "#fff !important",
            "&:hover": {
              backgroundColor: "#0060FF !important",
            },
            "&:disabled": {
              opacity: 0.5,
            },
          }}
        >
          {isPaymentLoading ? (
            <>
              <CircularProgress
                size={20}
                style={{
                  color: "#fff",
                }}
              />
            </>
          ) : (
            "Pay"
          )}
        </Button>
      </Grid>
      <Modal
        open={successNFTPayment}
        onClose={() => setSuccessNFTPayment(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        disableAutoFocus={true}
      >
        <Grid
          container
          sx={{
            backgroundColor: "#FFFFFF",
            padding: "15px 20px 15px 20px",
            borderRadius: "20px",
            maxWidth: "250px",
          }}
        >
          {nftPaymentMessage?.includes("You are the owner of one") && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <GiftIcon />
            </Grid>
          )}
          {nftPaymentMessage?.includes("You are the owner of one") && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "inherit",
                  color: "#3D3D3D",
                  fontSize: "17px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                Congratulations
              </Typography>
            </Grid>
          )}
          {!nftPaymentMessage?.includes("You are the owner of one") && (
            <Grid item xs={12} sx={{ marginTop: "10px" }}>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  color: "#3D3D3D",
                  fontSize: "17px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                NFT payment
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "inherit",
                color: "#3D3D3D",
                fontSize: "15px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              {nftPaymentMessage}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              onClick={() => {
                setSuccessNFTPayment(false);
              }}
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: "#0060FF !important",
                borderRadius: "5px",
                textTransform: "none",
                marginTop: "10px",
                height: "40px",
                width: "100%",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "inherit",
                color: "#fff !important",
                "&:hover": {
                  backgroundColor: "#0060FF !important",
                },
                "&:disabled": {
                  opacity: 0.5,
                },
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default OrderSummaryPage;
