export const monthsFilter = [
  { title: "January", id: 1 },
  { title: "February", id: 2 },
  { title: "March", id: 3 },
  { title: "April", id: 4 },
  { title: "May", id: 5 },
  { title: "June", id: 6 },
  { title: "July", id: 7 },
  { title: "August", id: 8 },
  { title: "September", id: 9 },
  { title: "October", id: 10 },
  { title: "November", id: 11 },
  { title: "December", id: 12 },
];

export const monthsTimelineFilter = [
  "Month",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const timelineMonths = [
  { name: "Jan", days: 31 },
  { name: "Feb", days: 28 },
  { name: "Mar", days: 31 },
  { name: "Apr", days: 30 },
  { name: "May", days: 31 },
  { name: "Jun", days: 30 },
  { name: "Jul", days: 31 },
  { name: "Aug", days: 31 },
  { name: "Sep", days: 30 },
  { name: "Oct", days: 31 },
  { name: "Nov", days: 30 },
  { name: "Dec", days: 31 },
];
export const mock_up_object = [
  {
    start_date: "2023-01-01",
    end_date: "2023-12-03",
    phases: [
      {
        slug: 1,
        name: "Pruning",
        startDate: "2023-01-01",
        endDate: "2023-03-03",
        number_of_videos: 2,
        phase_color: "#0060FF",
      },
      {
        slug: 2,
        name: "Pruning",
        startDate: "2023-05-01",
        endDate: "2023-06-01",
        number_of_videos: 3,
        phase_color: "#95FF95",
      },
    ],
  },
  {
    start_date: "2023-01-01",
    end_date: "2023-12-03",
    phases: [
      {
        slug: 3,
        name: "Pruning",
        startDate: "2023-01-01",
        endDate: "2023-12-30",
        number_of_videos: 2,
        phase_color: "#8FE3FE",
      },
    ],
  },
  {
    start_date: "2023-01-01",
    end_date: "2023-04-03",
    phases: [
      {
        slug: 4,
        name: "Pruning",
        startDate: "2023-01-01",
        endDate: "2023-02-03",
        number_of_videos: 2,
        phase_color: "#CF45FF",
      },
      {
        slug: 5,
        name: "Pruning",
        startDate: "2023-03-01",
        endDate: "2023-04-03",
        number_of_videos: 3,
        phase_color: "#FF8896",
      },
    ],
  },
  {
    start_date: "2023-01-01",
    end_date: "2023-12-31",
    phases: [
      {
        slug: 11,
        name: "Pruning",
        startDate: "2023-01-01",
        endDate: "2023-03-15",
        number_of_videos: 2,
        phase_color: "#95FF95",
      },
      {
        slug: 21,
        name: "Pruning",
        startDate: "2023-04-01",
        endDate: "2023-07-01",
        number_of_videos: 2,
        phase_color: "#95FF95",
      },
      {
        slug: 31,
        name: "Pruning",
        startDate: "2023-07-10",
        endDate: "2023-09-01",
        number_of_videos: 3,
        phase_color: "#95FF95",
      },
      {
        slug: 10,
        name: "Pruning",
        startDate: "2023-10-01",
        endDate: "2023-12-15",
        number_of_videos: 3,
        phase_color: "#0060FF",
      },
    ],
  },
];
