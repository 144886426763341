import React, { useState } from "react";
import DOMPurify from "dompurify";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { Grid, Typography } from "@mui/material";
//Icons
import PlayIcon from "../../assets/icons/PlayIcon";
import LikeIcon from "../../assets/icons/LikeIcon";
import CommentIcon from "../../assets/icons/CommentIcon";
import {
  addPostReactions,
  setExpandedBottomPrompt,
} from "../../redux/slices/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import BottomCommentDrawer from "../drawers/BottomCommentDrawer";
import DesktopCommentsView from "../comments/DesktopCommentsView";
import ShareIcon from "../../assets/icons/ShareIcon";
import SharePopupComponent from "../sharePopup/SharePopupComponent";

const HomepageItem = ({
  item,
  isTimeline,
  isDesktop,
  isTimelineWatched = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sanitizedHtml = DOMPurify.sanitize(item?.description);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
  const [shareOptionsModal, setShareOptionsModal] = useState(false);

  const [isLiked, setIsLiked] = useState(item?.liked_by_user);
  const [reactionsCount, setReactionsCount] = useState(item?.reactions || 0);
  const { access_token } = useSelector((state) => state.auth);
  const { headerFilterObjectState } = useSelector((state) => state.home);
  const postDate = moment(item?.created_at, "DD-MM-YYYY h:mm A").fromNow();

  const handlePostNavigate = () => {
    if (!item?.is_public && !access_token) {
      dispatch(setExpandedBottomPrompt(true));
    } else {
      navigate(`/post-details/${item?.slug}`);
    }
  };

  const handleAddReaction = (e, itemId) => {
    e.stopPropagation();
    if (access_token) {
      if (isLiked) {
        setReactionsCount(reactionsCount - 1);
      } else {
        setReactionsCount(reactionsCount + 1);
      }
      setIsLiked(!isLiked);

      let obj = {
        slug: itemId,
      };
      dispatch(addPostReactions(obj, itemId, true, headerFilterObjectState));
    } else {
      dispatch(setExpandedBottomPrompt(true));
    }
  };

  const handleAddComment = (e) => {
    e.stopPropagation();
    if (window.innerWidth < 768) {
      setIsDrawerOpen(true);
    } else {
      setIsCommentSectionOpen(!isCommentSectionOpen);
    }
  };

  const handleDrawerClose = (e) => {
    e.stopPropagation();

    setIsDrawerOpen(false);
  };
  const handleSharePost = (e) => {
    e.stopPropagation();

    setShareOptionsModal(true);
  };

  return (
    <>
      {isDesktop ? (
        <Grid onClick={() => handlePostNavigate()} sx={{ cursor: "pointer" }}>
          <Grid>
            <Typography
              sx={{
                fontFamily: "inherit",
                fontSize: { md: "20px", lg: "24px" },
                fontWeight: "600",
                color: "#3D3D3D",
                margin: "10px 0px",
              }}
            >
              {item?.title}
            </Typography>
          </Grid>

          {!isTimelineWatched && item?.description && (
            <Grid sx={{ margin: "10px 0px" }}>
              <Typography
                dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                sx={{
                  color: "rgba(61, 61, 61, 0.70)",
                  fontFamily: "inherit",
                  fontWeight: "500",
                  fontSize: "14px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: 4,
                  textOverflow: "ellipsis",
                  maxHeight: "100px",
                  opacity: 0.7,
                  "& p": {
                    margin: "0px !important",
                  },
                }}
              />
            </Grid>
          )}
          {(item?.type === "video" || item?.type === "image") && (
            <Grid
              sx={{
                height: isTimelineWatched ? "240px" : "360px",
                borderRadius: "5px",
                // backgroundImage: `url(${
                //   item?.type === "video" ? item?.cover_image : item?.image
                // })`,
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 11.62%, rgba(0, 0, 0, 0.60) 22.95%, rgba(0, 0, 0, 0.00) 37.04%), url(${
                  item?.type === "video" ? item?.cover_image : item?.image
                }),  #D9D9D9`,

                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "relative",
              }}
            >
              <Grid
                container
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  bottom: 0,
                  position: "absolute",
                  padding: "0px 10px",
                  marginBottom: "10px",
                }}
              >
                {item?.type === "video" ? (
                  <Grid item sx={{ cursor: "pointer" }} xs={1}>
                    <PlayIcon />
                  </Grid>
                ) : (
                  <Grid />
                )}

                <Grid
                  item
                  xs={"auto"}
                  sx={{
                    height: "19px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: item?.phase?.color,
                    borderRadius: "2px",
                    padding: "0px 10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "inherit",
                      fontSize: { xs: "10px", sm: "12px" },
                      fontWeight: "500",
                      maxWidth: "65px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      color: item?.phase?.text_color
                        ? item?.phase?.text_color
                        : "rgba(61, 61, 61, 0.80)",
                    }}
                  >
                    {item?.phase &&
                      item?.phase?.title?.charAt(0).toUpperCase() +
                        item?.phase?.title?.slice(1)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!isTimeline && !isTimelineWatched && (
            <Grid
              container
              sx={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                <Grid container>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleAddReaction(e, item?.slug)}
                  >
                    <LikeIcon filled={isLiked} />
                    <Typography
                      sx={{
                        fontFamily: "inherit",
                        fontSize: "14px",
                        fontWeight: "500",
                        marginLeft: "2px",
                        color: "rgba(61, 61, 61, 0.70)",
                      }}
                    >
                      {reactionsCount}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "25px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleAddComment(e)}
                  >
                    <CommentIcon />
                    <Typography
                      sx={{
                        fontFamily: "inherit",
                        fontSize: "14px",
                        fontWeight: "500",
                        marginLeft: "2px",
                        color: "rgba(61, 61, 61, 0.70)",
                      }}
                    >
                      {item?.comments}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginLeft: "25px",
                    }}
                    onClick={(e) => handleSharePost(e)}
                  >
                    <ShareIcon color={"#3D3D3D"} opacity />
                  </Grid>
                </Grid>
              </Grid>

              {item?.type !== "video" && item?.type !== "image" && (
                <Grid item>
                  <Grid
                    sx={{
                      height: "19px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: item?.phase?.color,
                      borderRadius: "2px",
                      padding: "0px 5px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "inherit",
                        fontSize: "10px",
                        fontWeight: "500",
                        color: item?.phase?.text_color
                          ? item?.phase?.text_color
                          : "rgba(61, 61, 61, 0.80)",
                      }}
                    >
                      {item?.phase &&
                        item?.phase?.title?.charAt(0).toUpperCase() +
                          item?.phase?.title?.slice(1)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                onClick={(e) => e.stopPropagation()}
                sx={{ marginTop: "10px" }}
              >
                {isCommentSectionOpen && (
                  <DesktopCommentsView
                    postId={item?.slug}
                    isHomepage
                    isCommentSectionOpen={isCommentSectionOpen}
                    setIsCommentSectionOpen={setIsCommentSectionOpen}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid onClick={() => handlePostNavigate()} sx={{ cursor: "pointer" }}>
          {(item?.type === "video" || item?.type === "image") && (
            <Grid
              sx={{
                height: {
                  xs: "250px",
                  sm: "350px",
                  md: isTimeline ? "250px" : "360px",
                },
                borderRadius: "5px",
                // backgroundImage: `url(${
                //   item?.type === "video" ? item?.cover_image : item?.image
                // })`,
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 11.62%, rgba(0, 0, 0, 0.60) 22.95%, rgba(0, 0, 0, 0.00) 37.04%), url(${
                  item?.type === "video" ? item?.cover_image : item?.image
                }),  #D9D9D9`,

                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "relative",
              }}
            >
              <Grid
                container
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  bottom: 0,
                  position: "absolute",
                  padding: "0px 10px",
                  marginBottom: "10px",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                  xs={7}
                  sm={9}
                  md={10}
                >
                  {item?.type === "video" && (
                    <Grid item sx={{ cursor: "pointer" }}>
                      <PlayIcon />
                    </Grid>
                  )}{" "}
                  <Grid
                    item
                    sx={{
                      width: "100%",
                      marginLeft: item?.type === "video" && "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "inherit",
                        fontSize: { xs: "15px", lg: "16px" },
                        fontWeight: "500",
                        color: "#FBFBFB",
                        maxWidth: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        textAlign: "left",
                      }}
                    >
                      {item?.title?.charAt(0).toUpperCase() +
                        item?.title?.slice(1)}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "inherit",
                        fontSize: { xs: "11px", lg: "12px" },
                        fontWeight: "500",
                        color: "#FBFBFB",
                      }}
                    >
                      {postDate?.charAt(0).toUpperCase() + postDate?.slice(1)}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={"auto"}
                  sx={{
                    height: "19px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: item?.phase?.color,
                    borderRadius: "2px",
                    padding: "0px 10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "inherit",
                      fontSize: { xs: "10px", sm: "12px" },
                      fontWeight: "500",
                      maxWidth: "65px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      color: item?.phase?.text_color
                        ? item?.phase?.text_color
                        : "rgba(61, 61, 61, 0.80)",
                    }}
                  >
                    {item?.phase &&
                      item?.phase?.title?.charAt(0).toUpperCase() +
                        item?.phase?.title?.slice(1)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {item?.type !== "video" && item?.type !== "image" && (
            <Grid>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "#3D3D3D",
                }}
              >
                {item?.title}
              </Typography>
            </Grid>
          )}
          {item?.description && (
            <Grid>
              <Typography
                dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                sx={{
                  color: "rgba(61, 61, 61, 0.80)",
                  fontFamily: "inherit",
                  fontWeight: "500",
                  fontSize: "14px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: 4,
                  textOverflow: "ellipsis",
                  maxHeight: "85px",
                  marginTop: "10px",
                  "& p": {
                    margin: "2px !important",
                  },
                }}
              />
            </Grid>
          )}
          {!isTimeline && (
            <Grid
              container
              sx={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                <Grid container>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleAddReaction(e, item?.slug)}
                  >
                    <LikeIcon filled={isLiked} />
                    <Typography
                      sx={{
                        fontFamily: "inherit",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(61, 61, 61, 0.70)",
                      }}
                    >
                      {reactionsCount}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "25px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleAddComment(e)}
                  >
                    <CommentIcon />
                    <Typography
                      sx={{
                        fontFamily: "inherit",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(61, 61, 61, 0.70)",
                      }}
                    >
                      {item?.comments}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginLeft: "25px",
                    }}
                    onClick={(e) => handleSharePost(e)}
                  >
                    <ShareIcon color={"#3D3D3D"} opacity />
                  </Grid>
                </Grid>
              </Grid>

              {item?.type !== "video" && item?.type !== "image" && (
                <Grid item>
                  <Grid
                    sx={{
                      height: "19px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: item?.phase?.color,
                      borderRadius: "2px",
                      padding: "0px 5px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "inherit",
                        fontSize: "10px",
                        fontWeight: "500",
                        color: item?.phase?.text_color
                          ? item?.phase?.text_color
                          : "rgba(61, 61, 61, 0.80)",
                      }}
                    >
                      {item?.phase &&
                        item?.phase?.title?.charAt(0).toUpperCase() +
                          item?.phase?.title?.slice(1)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}

          <BottomCommentDrawer
            isOpen={isDrawerOpen}
            handleCloseDrawer={handleDrawerClose}
            postId={item?.slug}
            isHomepage
          />
        </Grid>
      )}
      <SharePopupComponent
        shareOptionsModal={shareOptionsModal}
        setShareOptionsModal={setShareOptionsModal}
        postDetails={item}
      />
    </>
  );
};

export default HomepageItem;
