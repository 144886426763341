import React, { useEffect, useRef, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
//CSS
import "./App.css";

import axios from "axios";

//Private route
import PrivateRoute from "./routes/PrivateRoute";

//pages
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";

// import Page404 from "./pages/Page404";
//Auth pages
import Login from "./pages/authentication/Login";
import Register from "./pages/authentication/Register";
import FeedbackPage from "./pages/authentication/FeedbackPage";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import ResetPassword from "./pages/authentication/ResetPassword";

//Homepages
import Homepage1 from "./pages/homepages/Homepage1";
import HomePageContent from "./pages/homepages/HomePageContent";

//Landing Home Page
import Homepage from "./pages/Hompage";

//URL
import { API_URL, MAIN_API_URL } from "./config-global";
//Layouts
import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";

//Spinner
import LogoLoadingSpinner from "./components/loadingSpinner/LogoLoadingSpinner";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { wineryFetchSuccess } from "./redux/slices/authSlice";
//Helmet
import { Helmet } from "react-helmet-async";
import LoadingSpinner from "./components/loadingSpinner/LoadingSpinner";

import Experiences from "./pages/experiences/Experiences";
import Timeline from "./pages/timeline/Timeline";
import PostDetails from "./pages/posts/PostDetails";
import TimelineMonthDetails from "./pages/timeline/TimelineMonthDetails";
import ExperienceDetailsPage from "./pages/experiences/ExperienceDetails";
import OrderSummaryPage from "./pages/experiences/OrderSummaryPage";
//Stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import OrderSuccessPage from "./pages/experiences/OrderSuccessPage";
import OrdersPage from "./pages/experiences/OrdersPage";
import OrderDetailsPage from "./pages/experiences/OrderDetailsPage";
import Profile from "./pages/authentication/Profile";
import ComingSoon from "./pages/ComingSoon";
import VideoPlayerDesktop from "./pages/posts/VideoPlayerDesktop";

//Global Styles
import { GlobalStyles } from "@mui/material";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isValidCompany, setisValidCompany] = useState(false);

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const { winery, googleLoginLoading, access_token } = useSelector(
    (state) => state.auth
  );

  const isInitialRender = useRef(true);

  const InitialRenderForRedirection = localStorage.getItem(
    "InitialRenderForRedirection"
  );
  const onboarding_flow_checker = localStorage.getItem("onboarding_flow");
  const hostName = window.location.host;

  useEffect(() => {
    if (location.pathname === "/admin") {
      window.location.replace(`${API_URL}`);
    }
  }, [location?.pathname]);

  useEffect(() => {
    let modifiedHostName;

    if (hostName === process.env.REACT_APP_CENTRAL_DOMAIN) {
      modifiedHostName = "";
    } else {
      modifiedHostName = hostName
        .replace(`.${process.env.REACT_APP_CENTRAL_DOMAIN}`, "")
        .trim();
    }

    // If modifiedHostName is empty and not on the homepage, redirect to homepage
    if (modifiedHostName === "" && location.pathname !== "/homepage") {
      // window.location.href = `https://${process.env.REACT_APP_MAIN_API_DOMAIN}/homepage`;
      window.location.href = `${process.env.REACT_APP_APP_SCHEME}://${process.env.REACT_APP_CENTRAL_DOMAIN}`;
      return;
    }

    // If on the homepage and modifiedHostName is empty, set modifiedHostName to empty string
    if (modifiedHostName === "" && location.pathname === "/homepage") {
      modifiedHostName = "";
    }

    // If not the initial render and modifiedHostName is not empty, fetch data
    if (isInitialRender.current && modifiedHostName !== "") {
      const fetchData = async () => {
        try {
          setIsVideoLoaded(true);

          const obj = {
            winery_name: modifiedHostName,
          };

          const res = await axios.post(`${MAIN_API_URL}/api/get-winery`, obj);

          if (res.status === 200) {
            dispatch(wineryFetchSuccess(res?.data));
            setisValidCompany(true);

            const timeoutId = setTimeout(() => {
              setIsVideoLoaded(false);
              if (!res?.data?.data?.is_published) {
                navigate("/coming-soon");
              }
              if (!InitialRenderForRedirection) {
                if (res?.data?.data?.is_public === true) {
                  if (location?.pathname !== "/feedback") {
                    navigate("/homepage1");
                  }
                } else {
                  if (location?.pathname !== "/feedback") {
                    navigate("/login");
                  }
                }
              }
              if (
                (!onboarding_flow_checker &&
                  res?.data?.data?.is_public === true &&
                  location?.pathname !== "/feedback") ||
                (!onboarding_flow_checker &&
                  res?.data?.data?.is_public === false &&
                  access_token)
              ) {
                navigate("/homepage1");
              }
              localStorage.setItem("InitialRenderForRedirection", true);

              document.body.style.fontFamily = `${res?.data?.data?.font}`;
            }, 2500);

            return () => clearTimeout(timeoutId);
          }
        } catch (error) {
          setisValidCompany(false);
          dispatch(wineryFetchSuccess(null));
          const timeoutId2 = setTimeout(() => {
            setIsVideoLoaded(false);
            if (location.pathname !== "/homepage" || modifiedHostName !== "") {
              window.location.href = `${process.env.REACT_APP_APP_SCHEME}://${process.env.REACT_APP_CENTRAL_DOMAIN}`;
            }
          }, 2500);

          console.log("error", error);

          return () => clearTimeout(timeoutId2);
        }
      };

      fetchData();
    }

    // Update isInitialRender after the initial render
    if (isInitialRender.current) {
      isInitialRender.current = false;
    }
  }, []);

  useEffect(() => {
    if (winery?.favicon) {
      const faviconUrl = winery?.favicon;

      const favicon = document.createElement("link");
      favicon.rel = "icon";
      favicon.href = faviconUrl;

      const head = document.head || document.getElementsByTagName("head")[0];

      const existingFavicons = head.querySelectorAll('link[rel="icon"]');
      existingFavicons.forEach((existingFavicon) => {
        head.removeChild(existingFavicon);
      });

      head.appendChild(favicon);
    }
  }, [winery]);

  // const fontsToLoad = [
  //   {
  //     font: "Poppins",
  //     weights: [500, 600],
  //   },
  // ];
  return (
    <>
      {isVideoLoaded ? (
        <LogoLoadingSpinner setIsVideoLoaded={setIsVideoLoaded} />
      ) : googleLoginLoading ? (
        <>
          <LoadingSpinner />
        </>
      ) : (
        <>
          <Helmet>
            <title>
              {`${winery ? `${winery?.company_name} - ` : ""} Wine Grower`}
            </title>
          </Helmet>
          <GlobalStyles styles={{ p: { letterSpacing: 0 } }} />
          <Routes>
            {!isValidCompany && (
              <Route path="/homepage" element={<Homepage />} />
            )}
            <Route
              path="/login"
              element={
                <AuthLayout>
                  <Login />
                </AuthLayout>
              }
            />
            <Route
              path="/register"
              element={
                <AuthLayout>
                  <Register />
                </AuthLayout>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <AuthLayout>
                  <ForgotPassword />
                </AuthLayout>
              }
            />
            <Route
              path="/reset-password"
              element={
                <AuthLayout>
                  <ResetPassword />
                </AuthLayout>
              }
            />
            <Route
              path="/feedback"
              element={
                <AuthLayout>
                  <FeedbackPage />
                </AuthLayout>
              }
            />
            <Route
              path="/coming-soon"
              element={
                <AuthLayout>
                  <ComingSoon />
                </AuthLayout>
              }
            />
            <Route path="/" element={<PrivateRoute />}>
              <Route
                path="/"
                element={
                  <MainLayout>
                    <Home />
                  </MainLayout>
                }
              />
              <Route
                path="/timeline"
                element={
                  <MainLayout>
                    <Timeline />
                  </MainLayout>
                }
              />
              <Route
                path="/timeline-month-details/:id"
                element={<TimelineMonthDetails />}
              />
              <Route
                path="/experiences"
                element={
                  <MainLayout>
                    <Experiences />
                  </MainLayout>
                }
              />

              <Route
                path="/experience-details/:id"
                element={
                  <>
                    {window.innerWidth < 768 ? (
                      <Elements stripe={stripePromise}>
                        <ExperienceDetailsPage />
                      </Elements>
                    ) : (
                      <MainLayout>
                        <Elements stripe={stripePromise}>
                          <ExperienceDetailsPage />
                        </Elements>
                      </MainLayout>
                    )}
                  </>
                }
              />
              <Route
                path="/order-summary/:id"
                element={
                  <Elements stripe={stripePromise}>
                    <OrderSummaryPage />
                  </Elements>
                }
              />
              <Route path="/profile" element={<Profile />} />
              <Route
                path="/all-orders"
                element={
                  <>
                    {window.innerWidth < 768 ? (
                      <OrdersPage />
                    ) : (
                      <MainLayout>
                        <OrdersPage />
                      </MainLayout>
                    )}
                  </>
                }
              />
              <Route
                path="/order-details/:id"
                element={
                  <>
                    {window.innerWidth < 768 ? (
                      <OrderDetailsPage />
                    ) : (
                      <MainLayout>
                        <OrderDetailsPage />
                      </MainLayout>
                    )}
                  </>
                }
              />
              <Route
                path="/order-success"
                element={
                  <>
                    {window.innerWidth < 768 ? (
                      <OrderSuccessPage />
                    ) : (
                      <MainLayout>
                        <OrderSuccessPage />
                      </MainLayout>
                    )}
                  </>
                }
              />
              <Route
                path="/post-details/:id"
                element={
                  <>
                    {window.innerWidth < 768 ? (
                      <PostDetails />
                    ) : (
                      <MainLayout>
                        <PostDetails />
                      </MainLayout>
                    )}
                  </>
                }
              />
              <Route
                path="/post-details-desktop/:id"
                element={<VideoPlayerDesktop />}
              />
              <Route
                path="/about-us"
                element={
                  <>
                    {window.innerWidth < 768 ? (
                      <AboutUs />
                    ) : (
                      <MainLayout>
                        <AboutUs />
                      </MainLayout>
                    )}
                  </>
                }
              />

              {/* HOME PAGES */}
              <Route path="/homepage1" element={<Homepage1 />} />
              <Route path="/homepage-content" element={<HomePageContent />} />
            </Route>

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
