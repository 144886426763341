import React from "react";
//Helmet Component
import HelmetPage from "../components/helmetPage/HelmetPage";

const Homepage = () => {
  return (
    <div>
      <HelmetPage pageTitle={"Home Page"} />
      Homepage
    </div>
  );
};

export default Homepage;
