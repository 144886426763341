import React, { useEffect, useState } from "react";
import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Component
import ExperiencesHomeItem from "../../components/experiences/ExperiencesHomeItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllExperiences } from "../../redux/slices/experiencesSlice";
//Utils
import { filterOptions } from "../../utils/FilterUtils";
import ChevronDown from "../../assets/icons/ChevronDown";
import ChevronDownExperiences from "../../assets/icons/ChevronDownExperiences";

const Experiences = () => {
  const dispatch = useDispatch();
  const { allExperiences } = useSelector((state) => state.experiences);

  const [selectedFilter, setSelectedFilter] = useState("");
  const handleChange = (event) => {
    if (event.target.value === "Price") {
      setSelectedFilter("");
    } else {
      setSelectedFilter(event.target.value);
    }
  };

  const sortedData = [...allExperiences];

  if (selectedFilter === "Low") {
    sortedData.sort(
      (a, b) =>
        (a?.discountPrice ? a?.discountPrice : a?.price) -
        (b?.discountPrice ? b?.discountPrice : b?.price)
    );
  } else if (selectedFilter === "High") {
    sortedData.sort(
      (a, b) =>
        (b?.discountPrice ? b?.discountPrice : b?.price) -
        (a?.discountPrice ? a?.discountPrice : a?.price)
    );
  }

  useEffect(() => {
    dispatch(fetchAllExperiences());
  }, []);

  return (
    <Box
      sx={{
        // height: "60vh",
        padding: {
          xs: "10px 20px",
          sm: "10px 50px",
          lg: "10px 50px 10px 60px",
        },
      }}
    >
      {window.innerWidth > 768 && (
        <Typography
          sx={{
            fontSize: "30px",
            color: "#3D3D3D",
            fontFamily: "Fraunces",
            fontWeight: "600",
            margin: "10px 0px 20px 0px",
          }}
        >
          Experiences
        </Typography>
      )}
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          margin: "0px 0px 15px 0px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "inherit",
            fontSize: { xs: "13px", sm: "16px" },
            color: "rgba(61, 61, 61, 0.70)",
          }}
        >
          Sort by:
        </Typography>
        <Box sx={{ marginLeft: "10px" }}>
          <Select
            value={selectedFilter}
            onChange={handleChange}
            IconComponent={ChevronDownExperiences}
            displayEmpty={true}
            renderValue={(value) => (selectedFilter ? value : "Price")}
            variant="outlined"
            sx={{
              width: { xs: "70px", sm: "90px" },
              fontFamily: "inherit",
              fontSize: { xs: "13px", lg: "16px" },
              "& .MuiOutlinedInput-input": {
                padding: { xs: "3px 4px 3px 15px", lg: "3px 4px 3px 5px" },
                fontFamily: "inherit",
                display: "flex",
                justifyContent: "center",
                opacity: 0.9,
                // fontWeight: 600,
              },
              "& .MuiSelect-icon": {
                // right: "11px",
              },
            }}
            MenuProps={{
              style: {
                maxHeight: "300px",
                fontFamily: "inherit",
              },
            }}
          >
            {filterOptions.map((x) => {
              return (
                <MenuItem
                  value={x}
                  key={x}
                  sx={{
                    color: "#3D3D3D",
                    fontFamily: "inherit",
                  }}
                >
                  {x}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Grid>
      {window.innerWidth < 768 ? (
        <>
          {sortedData?.map((experience, index) => {
            return (
              <>
                <Box key={index}>
                  <ExperiencesHomeItem experience={experience} />
                </Box>
              </>
            );
          })}
        </>
      ) : (
        <>
          <Grid
            container
            sx={{ width: "100%", flexDirection: "row", display: "flex" }}
            gap={2}
          >
            {sortedData?.map((experience, index) => {
              return (
                <Grid item xs={4} sm={5} md={3.7} lg={3.8} xl={2.8} key={index}>
                  <ExperiencesHomeItem experience={experience} />
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default Experiences;
