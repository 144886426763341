import React, { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import moment from "moment";
//utils
import { timelineMonths } from "../../utils/MonthsUtils";
//Redux/ api
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPhase } from "../../redux/slices/timelineSlice";

const TimelineComponent = ({ timelineContent, scrolling }) => {
  const dayWidthInPx = window.innerWidth >= 992 ? 8.8 : 4.4;
  const { selectedPhase } = useSelector((state) => state.timeline);
  const dispatch = useDispatch();

  const cursorRef = useRef();

  const [testChartHeight, setTestChartHeight] = useState();

  // Function to calculate the width of the timeline bar
  const calculatePhaseWidth = (startDate, endDate) => {
    const startMoment = moment(startDate);

    const endMoment = moment(endDate);

    const numberOfMonths = endMoment.diff(startMoment, "days", true);

    // Assuming 68px per month
    const width = numberOfMonths * dayWidthInPx;

    return width;
  };

  // Function to calculate the left margin of the phase in the timeline
  const calculateLeftMargin = (startDate, prevMonth, currMonth) => {
    const startMoment = moment(startDate);
    const endMoment = moment(prevMonth?.endDate);

    const monthNumber = startMoment.month();

    const daysPassed = startMoment.date();
    let diffBetweenMonths;

    if (prevMonth) {
      diffBetweenMonths = startMoment.diff(endMoment, "days");
    }

    const additionalMargin = daysPassed * dayWidthInPx;
    let monthWidthTest = 0;
    timelineMonths?.map((x, index) => {
      if (index < monthNumber) {
        monthWidthTest += x?.days * dayWidthInPx;
      }
    });

    const marginLeft = prevMonth
      ? diffBetweenMonths > 0
        ? diffBetweenMonths * (dayWidthInPx - 1)
        : 0
      : monthWidthTest - dayWidthInPx + additionalMargin;

    return marginLeft;
  };

  const handlePhaseSelect = (phase) => {
    if (phase === selectedPhase) {
      dispatch(setSelectedPhase(""));
    } else {
      dispatch(setSelectedPhase(phase));
    }
  };

  const scrollToCursor = () => {
    // Using scrollIntoView to scroll to the specific div
    if (cursorRef.current) {
      cursorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  useEffect(() => {
    if (timelineContent?.timeline) {
      const chartHeightTest = document.getElementById(
        "line-chart-container"
      ).offsetHeight;

      setTestChartHeight(chartHeightTest);
    }
  }, [selectedPhase, timelineContent, scrolling]);

  useEffect(() => {
    scrollToCursor();
  }, []);

  return (
    <Box sx={{ position: "relative", zIndex: 10, top: 0 }}>
      <Box
        id="line-chart-container"
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowX: "scroll",
          marginTop: "10px",
          marginRight: "20px",
          "&::-webkit-scrollbar": {
            display: "block",
          },
        }}
      >
        <Box
          sx={{
            marginBottom: selectedPhase ? "10px" : "50px",
            position: "relative",
            width: "700px",
            marginLeft: "10px",
          }}
        >
          <Box
            sx={{
              height: selectedPhase
                ? `${testChartHeight - 65}px`
                : `${testChartHeight - 55}px`,
              position: "absolute",
              left: calculateLeftMargin(moment().format("YYYY-MM-DD")),
              width: "2px",
              backgroundColor: "#000000",
              zIndex: 100,
              marginTop: "5px",
            }}
            ref={cursorRef}
          />
          <Box
            className={`timeline-list ${
              selectedPhase ? "selectedTimeline" : ""
            }`}
            sx={{
              marginBottom: selectedPhase ? "50px" : "0px",
              width: "700px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {timelineContent?.timeline &&
              timelineContent?.timeline?.map((value, index) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "stretch",
                    marginTop: "10px",
                    position: "relative",
                  }}
                  key={index}
                >
                  {value.phases?.map((phase, index1) => (
                    <Box
                      key={phase?.slug}
                      sx={{
                        height: {
                          xs:
                            window.innerWidth < 768 &&
                            scrolling &&
                            !!selectedPhase === false
                              ? "0px"
                              : (selectedPhase === phase?.slug ||
                                  selectedPhase === "") &&
                                "auto",
                          md: "auto",
                        },
                        minWidth: `${calculatePhaseWidth(
                          phase?.startDate,
                          phase?.endDate
                        )}px`,
                        width: `${calculatePhaseWidth(
                          phase?.startDate,
                          phase?.endDate
                        )}px`,
                        maxWidth: `${calculatePhaseWidth(
                          phase?.startDate,
                          phase?.endDate
                        )}px`,
                        border: {
                          xs: `${
                            selectedPhase === phase?.slug ||
                            selectedPhase === ""
                              ? 1
                              : 3
                          }px solid ${phase?.phase_color}`,
                          md: `1px solid ${phase?.phase_color}`,
                        },
                        backgroundColor:
                          selectedPhase === phase?.slug
                            ? phase?.phase_color
                            : "#FFFFFF",
                        opacity: {
                          xs:
                            selectedPhase === phase?.slug ||
                            selectedPhase === ""
                              ? 1
                              : 0.5,
                          md: 1,
                        },
                        borderRadius: "5px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // position: "absolute",
                        marginLeft: `${calculateLeftMargin(
                          phase?.startDate,
                          value.phases[index1 - 1],
                          phase?.title
                        )}px`,
                        position: "relative",
                        "&:after": {
                          display:
                            selectedPhase === phase?.slug ? "block" : "none",
                          opacity: selectedPhase === phase?.slug ? 1 : 0,
                          position: "absolute",
                          left: "-1px",
                          top: "-1px",
                          height: "calc(100% + 6px)",
                          background:
                            selectedPhase === phase?.slug
                              ? phase?.phase_color
                              : "#FFFFFF",
                          color:
                            selectedPhase === phase?.slug
                              ? phase?.text_color
                              : "#3d3d3de6",
                          content: "attr(title)",
                          width: "auto",
                          borderRadius: "5px",
                          padding: "0px 2px",
                          fontSize: "13px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "max-content",
                          minWidth: "100%",
                          textAlign: "center",
                          zIndex: 1,
                        },
                      }}
                      title={`${phase?.title} (${phase?.number_of_videos})`}
                      onClick={() => handlePhaseSelect(phase?.slug)}
                    >
                      <Box sx={{ width: "100%", display: "flex" }}>
                        <a
                          className={
                            selectedPhase === phase?.slug
                              ? "timeline-hyperlink selected"
                              : "timeline-hyperlink"
                          }
                          style={{
                            color:
                              selectedPhase === phase?.slug
                                ? phase?.text_color
                                : "#3d3d3de6",
                            // display: "flex",
                            fontFamily: "inherit",
                            fontSize: "13px",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            maxWidth: "90%",
                            display:
                              window.innerWidth < 768 &&
                              scrolling &&
                              !!selectedPhase === false
                                ? "none"
                                : "inline-block",
                            overflow: "hidden",
                            marginLeft: "auto",
                            marginRight: "auto",
                            textOverflow: "ellipsis",
                            textWrap: "nowrap",
                            whiteSpace: "nowrap",
                          }}
                          title={`${phase?.title} (${phase?.number_of_videos})`}
                        >
                          {`${phase?.title} (${phase?.number_of_videos})`}
                        </a>
                      </Box>
                    </Box>
                  ))}
                </Box>
              ))}
          </Box>
          <Box
            sx={{
              bottom: selectedPhase ? "0px" : "-50px",
              display: "flex",
              width: "100%",
              position: "absolute",
            }}
          >
            {timelineMonths.map((month, index) => (
              <Box
                key={month?.name}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  minWidth: month?.days * dayWidthInPx,
                  width: month?.days * dayWidthInPx,
                  maxWidth: month?.days * dayWidthInPx,
                }}
              >
                <Box
                  sx={{
                    width: "1px",
                    height: "20px",
                    backgroundColor: "rgba(61, 61, 61, 0.6)",
                    position: "absolute",
                    top: 0,
                  }}
                />
                {[1, 2, 3, 4].map((value, index) => {
                  const value1 = (month?.days * dayWidthInPx) / 4;
                  const marginLeftValue = value1 * index;

                  return (
                    index !== 0 && (
                      <Box
                        key={index}
                        sx={{
                          width: "1px",
                          height: "10px",
                          backgroundColor: "rgba(61, 61, 61, 0.6)",
                          position: "absolute",
                          top: 0,
                          marginLeft: `${marginLeftValue}px`,
                        }}
                      />
                    )
                  );
                })}

                <Box
                  sx={{
                    height: `${testChartHeight - 5}px`,
                    position: "absolute",
                    width: "1px",
                    backgroundColor: "rgba(61, 61, 61, 0.20)",
                    zIndex: -1,
                    marginTop: "5px",
                    top: testChartHeight ? -testChartHeight : -220,
                    marginLeft: "0px",
                  }}
                />
                <Box sx={{ marginTop: "25px", fontSize: "12px" }}>
                  {month?.name}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TimelineComponent;
