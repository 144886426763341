import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
//Redux/ Api
import { useDispatch, useSelector } from "react-redux";
import { fetchPostDetails } from "../../redux/slices/homeSlice";
//Loading Spinner
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
//Pages
import PlayerContainer from "../../components/videoPlayer/CustomVideo";
import { ChevronLeft } from "@mui/icons-material";

const VideoPlayerDesktop = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { postDetails, isLoading } = useSelector((state) => state.home);
  const { id } = useParams();
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchPostDetails(id));
      setIsDataLoaded(true);
    };

    fetchData();
  }, [dispatch, id]);

  if (!isDataLoaded || isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <Box>
      {postDetails && Object.keys(postDetails).length !== 0 ? (
        <Grid container>
          <PlayerContainer postDetails={postDetails} />
        </Grid>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <Box>
              <Box
                onClick={() => navigate(-1)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  cursor: "pointer",
                  padding: "2px 10px",
                  color: "#3D3D3D",
                }}
              >
                <ChevronLeft
                  sx={{
                    fontSize: "22px",
                    marginLeft: "-5px",
                    color: "#3D3D3D",
                  }}
                />
                Back
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "200px",
                }}
              >
                Couldn't fetch post details.
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default VideoPlayerDesktop;
