import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";

//Redux/ Api
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHomeContent,
  fetchHomeFilters,
  headerFilterObject,
} from "../../redux/slices/homeSlice";

//Icons
import ChevronUp from "../../assets/icons/headerIcons/ChevronUp";
import ChevronDown from "../../assets/icons/headerIcons/ChevronDown";
import CloseIcon from "../../assets/icons/headerIcons/CloseIcon";

//Utils
import { monthsFilter } from "../../utils/MonthsUtils";

const HeaderFilter = () => {
  const dispatch = useDispatch();
  const { phaseFilter, tagsFilter } = useSelector((state) => state.home);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleOptionClick = (option) => {
    setSelectedOption((prevOption) => (prevOption === option ? null : option));
  };

  const handleItemClick = (item) => {
    setSelectedItems((prevItems) => {
      const existingOption = prevItems.find(
        (obj) => obj.type === selectedOption
      );

      if (existingOption) {
        // If option already exists, update its values
        const updatedOption = {
          ...existingOption,
          values: existingOption.values?.includes(item)
            ? existingOption.values?.filter((i) => i !== item)
            : [...existingOption.values, item],
        };

        return prevItems?.map((obj) =>
          obj.type === selectedOption ? updatedOption : obj
        );
      } else {
        // If option doesn't exist, create a new one
        return [...prevItems, { type: selectedOption, values: [item] }];
      }
    });
  };

  // Convert selectedItems to the desired object structure
  const convertedObject = selectedItems?.reduce((result, item) => {
    result[item?.type] = item?.values;
    return result;
  }, {});

  const handleOptionClear = () => {
    setSelectedItems((prevItems) => {
      // Filter out the selected option from the array
      const updatedItems = prevItems.filter(
        (obj) => obj.type !== selectedOption
      );
      return updatedItems;
    });
  };
  const renderItems = () => {
    let items = [];
    switch (selectedOption) {
      case "months":
        items = monthsFilter;
        break;
      case "phases":
        items = phaseFilter;
        break;
      case "tags":
        items = tagsFilter;
        break;
      default:
        break;
    }

    return (
      <>
        {items.map((item, index) => (
          <Box
            key={index}
            onClick={() => handleItemClick(item?.id)}
            sx={{
              height: { xs: "24px", sm: "36px" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
              backgroundColor: selectedItems.some(
                (obj) =>
                  obj?.type === selectedOption &&
                  obj?.values?.includes(item?.id)
              )
                ? "#EBEADF"
                : "",
              border: `0.5px solid ${
                selectedItems.some(
                  (obj) =>
                    obj?.type === selectedOption &&
                    obj?.values?.includes(item?.id)
                )
                  ? "#EBEADF"
                  : "rgba(61, 61, 61, 0.50)"
              }`,
              margin: "5px 10px 5px 0px",
              padding: { xs: "0px 15px", sm: "0px 20px" },
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                color: selectedItems.some(
                  (obj) =>
                    obj?.type === selectedOption &&
                    obj?.values?.includes(item?.id)
                )
                  ? "#3D3D3D"
                  : "rgba(61, 61, 61, 0.70)",
                fontSize: "14px",
                fontWeight: selectedItems.some(
                  (obj) =>
                    obj?.type === selectedOption &&
                    obj?.values?.includes(item?.id)
                )
                  ? "600"
                  : "500",
                fontFamily: "inherit",
                marginRight: "5px",
                maxWidth: "270px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {item?.title?.charAt(0).toUpperCase() + item?.title?.slice(1)}
            </Typography>
          </Box>
        ))}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
          onClick={() =>
            selectedItems?.some(
              (obj) => obj?.type === selectedOption && obj?.values.length > 0
            ) && handleOptionClear()
          }
        >
          <Box
            sx={{
              height: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
              backgroundColor: selectedItems?.some(
                (obj) => obj?.type === selectedOption && obj?.values.length > 0
              )
                ? "#EBEADF"
                : "",
              cursor: "pointer",
              padding: "2px 5px",
            }}
          >
            <Typography
              sx={{
                color: selectedItems?.some(
                  (obj) =>
                    obj?.type === selectedOption && obj?.values.length > 0
                )
                  ? "#3D3D3D"
                  : "rgba(61, 61, 61, 0.50)",
                fontSize: "12px",
                fontWeight: "500",
                fontFamily: "inherit",
                marginRight: "5px",
              }}
            >
              Clear
            </Typography>
            <CloseIcon
              width={"10"}
              height={"10"}
              color={
                selectedItems?.some(
                  (obj) =>
                    obj?.type === selectedOption && obj?.values.length > 0
                )
                  ? "#3D3D3D"
                  : "rgba(61, 61, 61, 0.50)"
              }
            />
          </Box>
        </Box>
      </>
    );
  };

  useEffect(() => {
    dispatch(fetchHomeFilters());
  }, []);

  useEffect(() => {
    if (selectedItems) {
      dispatch(fetchHomeContent(convertedObject));
      dispatch(headerFilterObject(convertedObject));
    }
  }, [selectedItems]);

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: "10px",
          padding: { xs: "0px 10px", sm: "0px" },
        }}
        columnGap={2}
      >
        {["months", "phases", "tags"].map((option, index) => (
          <Grid
            key={index}
            item
            xs={"auto"}
            onClick={() => handleOptionClick(option)}
            sx={{
              height: { xs: "25px", sm: "40px" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
              padding: { xs: "0px 8px", sm: "0px 25px" },
              backgroundColor: selectedOption === option ? "#EBEADF" : "",
              border: `0.5px solid ${
                selectedOption === option ? "#EBEADF" : "rgba(61, 61, 61, 0.10)"
              }`,
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                color:
                  selectedOption === option
                    ? "#3D3D3D"
                    : "rgba(61, 61, 61, 0.70)",
                fontSize: "14px",
                fontWeight: "600",
                fontFamily: "inherit",
                marginRight: "5px",
              }}
            >
              {option?.charAt(0).toUpperCase() + option?.slice(1)}
            </Typography>
            {selectedOption === option ? <ChevronUp /> : <ChevronDown />}
          </Grid>
        ))}
      </Grid>
      {selectedOption && (
        <Divider
          sx={{
            backgroundColor: "rgba(61, 61, 61, 0.30)",
            width: "100%",
            margin: "10px 0px 0px 0px",
            display: { xs: "flex", sm: "none" },
          }}
        />
      )}

      <Grid container sx={{ padding: { xs: "0px 10px", sm: "0px" } }}>
        {selectedOption && (
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            {/* Render items based on the selected option */}
            {renderItems()}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default HeaderFilter;
