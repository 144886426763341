import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setExpandedBottomPrompt } from "../../redux/slices/homeSlice";

const TimelineItem = ({ post }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.auth);

  const postDate = moment(post?.created_at, "DD-MM-YYYY h:mm A").fromNow();

  const handlePostNavigate = () => {
    if (!post?.is_public && !access_token) {
      dispatch(setExpandedBottomPrompt(true));
    } else {
      navigate(`/post-details/${post?.slug}`);
    }
  };
  return (
    <Box
      sx={{
        width: "137px",
        height: "137px",
        borderRadius: "5px",
        marginRight: "5px",
        position: "relative",
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 20.36%, rgba(0, 0, 0, 0.00) 45.81%), url(${
          post?.type === "video" ? post?.cover_image : post?.image
        }),  #ECECEC`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      onClick={() => handlePostNavigate()}
    >
      <Grid
        container
        sx={{
          position: "absolute",
          bottom: 0,
          padding: "2px 5px",
          flexDirection: "column",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: "2px",
          }}
        >
          <Typography
            sx={{
              fontSize: "10px",
              padding: "2px 5px",
              borderRadius: "2px",
              maxWidth: "80px",
              backgroundColor: post?.phase?.color,
              color: post?.phase?.text_color,
              fontFamily: "inherit",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textWrap: "nowrap",
              whiteSpace: "nowrap",
              maxWidth: "100px",
              letterSpacing: 0,
            }}
          >
            {post?.phase?.title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontSize: "11px",
              color: "#FFFFFF",
              fontWeight: 500,
              fontFamily: "inherit",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textWrap: "nowrap",
              whiteSpace: "nowrap",
              maxWidth: "100px",
              letterSpacing: 0,
            }}
          >
            {post?.title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontSize: "9px",
              color: "rgba(255, 255, 255, 0.80)",
              fontFamily: "inherit",
              fontWeight: 500,
              letterSpacing: 0,
            }}
          >
            {postDate?.charAt(0).toUpperCase() + postDate?.slice(1)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TimelineItem;
