import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addPostComments,
  fetchPostComments,
} from "../../redux/slices/homeSlice";

//Validation and Formik
import * as yup from "yup";
import { Form, Formik } from "formik";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import SendIcon from "../../assets/icons/SendIcon";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import CommentComponent from "./CommentComponent";

const DesktopCommentsView = ({ postId, isHomepage, isCommentSectionOpen }) => {
  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.auth);
  const { postComments, isCommentsLoading, headerFilterObjectState } =
    useSelector((state) => state.home);
  const [showAll, setShowAll] = useState(false);
  const visibleElements = showAll ? postComments : postComments?.slice(0, 1);

  const validationSchema = yup.object({
    postComment: yup
      .string()
      .required("Comment is required.")
      .min(3, "Comment must be at least 3 characters long."),
  });
  const handleSubmit = (values, { resetForm }) => {
    let obj = {
      slug: postId,
      text: values.postComment,
    };

    dispatch(addPostComments(obj, postId, isHomepage, headerFilterObjectState));
    resetForm();
  };

  useEffect(() => {
    if (isCommentSectionOpen) {
      dispatch(fetchPostComments(postId));
    }
  }, [postId, isCommentSectionOpen]);
  return (
    <Grid container>
      <Grid item xs={12}>
        {access_token && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              alt={"User"}
              src="/"
              sx={{
                width: 50,
                height: 50,
                backgroundColor: "#1976d2",
                margin: "10px 5px 10px 0px",
              }}
            />
            <Box
              sx={{
                width: {
                  xs: "80%",
                  sm: "100%",
                },
              }}
            >
              <Formik
                initialValues={{
                  postComment: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values, formikBag) =>
                  handleSubmit(values, formikBag)
                }
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  touched,
                  errors,
                  submitForm,
                }) => (
                  <Form>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <OutlinedInput
                        name="postComment"
                        placeholder="Add a comment..."
                        variant="standard"
                        value={values.postComment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={() => submitForm()}>
                              <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        error={
                          touched.postComment && Boolean(errors.postComment)
                        }
                        helperText={
                          touched.postComment ? errors.postComment : ""
                        }
                        sx={{
                          width: "100%",
                          fontSize: "12px",
                          fontFamily: "inherit",
                          backgroundColor: "#F7F7F7",
                          "& fieldset": {
                            border:
                              !Boolean(errors.postComment) && "transparent",
                          },
                          "& .MuiInput-input": {
                            fontSize: "12px",
                            fontFamily: "inherit",
                            color: "#3D3D3D",
                          },
                          "& .MuiInput-root": {
                            borderBottomColor: errors.postComment
                              ? "#f44336" // Set the error color as per your theme
                              : "#3D3D3D !important",
                          },
                          "& .MuiFormHelperText-root.Mui-error": {
                            fontSize: "10px",
                            color: "#f44336",
                          },
                        }}
                      />
                    </Box>
                    {touched.postComment && (
                      <Typography sx={{ fontSize: "10px", color: "#f44336" }}>
                        {errors?.postComment}
                      </Typography>
                    )}
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        )}
        {isCommentsLoading ? (
          <Box>
            <LoadingSpinner pageHeight="100px" size={30} />
          </Box>
        ) : postComments?.length > 0 ? (
          visibleElements?.map((comment, index) => {
            return (
              <Box key={index} sx={{ padding: "10px 0px" }}>
                <CommentComponent item={comment} isDesktop />
              </Box>
            );
          })
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#3D3D3D",
            }}
          >
            No comments available
          </Box>
        )}
        {postComments?.length > 1 && (
          <Box
            sx={{
              display: "flex",
              alignItems: showAll ? "flex-end" : "stretch",
            }}
            onClick={() => setShowAll(!showAll)}
          >
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: "600",
                color: "#3D3D3D",
                fontFamily: "inherit",
                opacity: 0.7,
              }}
            >
              View {showAll ? "less" : "more"} comments
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default DesktopCommentsView;
