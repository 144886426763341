import React from "react";
import { Box } from "@mui/material";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const GoogleMapsComponent = ({ lat, long, api_key }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${api_key}`,
  });

  const center = { lat: lat, lng: long };
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {!isLoaded ? (
        <Box>
          <LoadingSpinner />
        </Box>
      ) : (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={16}>
          <Marker position={center} />
        </GoogleMap>
      )}
    </Box>
  );
};

export default GoogleMapsComponent;
