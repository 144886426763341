import React from "react";

const ChevronRightHome = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M21.6667 13C21.6666 13.2873 21.5524 13.5628 21.3493 13.7659L14.8493 20.2659C14.645 20.4633 14.3713 20.5725 14.0873 20.57C13.8032 20.5675 13.5315 20.4536 13.3306 20.2527C13.1298 20.0519 13.0158 19.7802 13.0134 19.4961C13.0109 19.2121 13.1201 18.9384 13.3174 18.7341L17.9682 14.0833L5.41669 14.0833C5.12937 14.0833 4.85382 13.9692 4.65065 13.766C4.44749 13.5629 4.33335 13.2873 4.33335 13C4.33335 12.7127 4.44749 12.4371 4.65065 12.234C4.85382 12.0308 5.12937 11.9167 5.41669 11.9167L17.9682 11.9167L13.3174 7.26593C13.1201 7.06161 13.0109 6.78796 13.0134 6.50391C13.0158 6.21987 13.1298 5.94815 13.3306 5.74729C13.5315 5.54643 13.8032 5.4325 14.0873 5.43003C14.3713 5.42757 14.645 5.53676 14.8493 5.7341L21.3493 12.2341C21.5524 12.4372 21.6666 12.7127 21.6667 13Z"
        fill="white"
      />
    </svg>
  );
};

export default ChevronRightHome;
