const hostName = window.location.host;
const modifiedHostName = hostName
  .replace(`.${process.env.REACT_APP_CENTRAL_DOMAIN}`, "")
  .trim();
export const API_URL =
  `${process.env.REACT_APP_APP_SCHEME}://${modifiedHostName}.${process.env.REACT_APP_MAIN_API_DOMAIN}` ||
  "";
// export const API_URL = "https://ghadi.mywinegrower.co" || "";

export const MAIN_API_URL = `${process.env.REACT_APP_APP_SCHEME}://${process.env.REACT_APP_MAIN_API_DOMAIN}`;
// http://mywinegrower.co/api/get-winery
