import React from "react";

const OnboardingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="86"
      viewBox="0 0 74 86"
      fill="none"
    >
      <g clipPath="url(#clip0_577_788)">
        <path
          d="M13.7914 41.6779C11.0682 39.0081 9.98107 35.0756 10.9644 31.4056C12.4694 25.789 18.3018 22.4482 23.9569 23.9635C26.4536 24.6325 28.6205 26.1831 30.0545 28.3215L26.8352 30.4489C25.9283 29.0897 24.5457 28.1079 22.9602 27.6831C19.3649 26.7197 15.6664 28.8383 14.7096 32.4091C14.085 34.7401 14.7802 37.2386 16.5061 38.9369L13.7914 41.6779Z"
          fill="white"
        />
        <path
          d="M35.1352 48.9396C29.2804 47.3708 25.7899 41.3724 27.348 35.5574C28.9061 29.7425 34.9282 26.293 40.783 27.8618C46.6379 29.4306 50.1284 35.429 48.5703 41.2439C47.0121 47.0589 40.9901 50.5084 35.1352 48.9396ZM39.7863 31.5814C36.0038 30.5679 32.0997 32.8041 31.0931 36.5609C30.0865 40.3178 32.3493 44.2064 36.1319 45.22C39.9145 46.2335 43.8185 43.9973 44.8252 40.2404C45.8318 36.4836 43.5689 32.5949 39.7863 31.5814Z"
          fill="white"
        />
        <path
          d="M56.7698 53.5411L56.0439 49.7586C58.5767 49.2945 60.6519 47.3921 61.3164 44.9123C62.2732 41.3415 60.1294 37.6575 56.5341 36.6941C55.0485 36.2961 53.5208 36.405 52.1173 36.999L50.576 33.4631C52.7965 32.5166 55.1963 32.349 57.5307 32.9745C63.1859 34.4898 66.5664 40.2992 65.0615 45.9158C64.0183 49.809 60.767 52.8049 56.7665 53.5535L56.7698 53.5411Z"
          fill="white"
        />
        <path
          d="M22.4087 61.4777C16.5538 59.9089 13.0633 53.9105 14.6214 48.0955C16.1795 42.2805 22.2016 38.8311 28.0565 40.3999C33.9113 41.9687 37.4018 47.967 35.8437 53.782C34.2856 59.597 28.2635 63.0465 22.4087 61.4777ZM27.0598 44.1195C23.2772 43.106 19.3732 45.3422 18.3665 49.099C17.3599 52.8558 19.6228 56.7445 23.4053 57.7581C27.1879 58.7716 31.092 56.5353 32.0986 52.7785C33.1052 49.0217 30.8423 45.133 27.0598 44.1195Z"
          fill="white"
        />
        <path
          d="M27.1587 78.6974C21.3038 77.1286 17.8133 71.1303 19.3714 65.3153C20.9295 59.5003 26.9516 56.0508 32.8065 57.6196C38.6613 59.1884 42.1518 65.1868 40.5937 71.0018C39.0356 76.8167 33.0135 80.2662 27.1587 78.6974ZM31.8098 61.3392C28.0272 60.3257 24.1232 62.562 23.1165 66.3188C22.1099 70.0756 24.3728 73.9643 28.1553 74.9778C31.9379 75.9913 35.842 73.7551 36.8486 69.9983C37.8552 66.2415 35.5923 62.3528 31.8098 61.3392Z"
          fill="white"
        />
        <path
          d="M39.8852 66.1603C34.0304 64.5915 30.5399 58.5931 32.098 52.7782C33.6561 46.9632 39.6782 43.5137 45.533 45.0825C51.3879 46.6513 54.8784 52.6497 53.3203 58.4646C51.7621 64.2796 45.7401 67.7291 39.8852 66.1603ZM44.5363 48.8021C40.7538 47.7886 36.8497 50.0248 35.8431 53.7817C34.8365 57.5385 37.0993 61.4272 40.8819 62.4407C44.6645 63.4542 48.5685 61.218 49.5752 57.4612C50.5818 53.7043 48.3189 49.8156 44.5363 48.8021Z"
          fill="white"
        />
        <path
          d="M39.3695 16.0144C34.9502 14.8303 31.2207 13.419 28.45 12.2248L29.9859 8.68952C37.6383 11.9891 53.017 17.0135 69.9849 13.5601L70.7798 17.3345C58.9323 19.7546 47.8584 18.289 39.3695 16.0144Z"
          fill="white"
        />
        <path
          d="M44.6759 27.3767L41.8203 24.7511C44.0758 22.3256 45.5147 19.2029 46.0695 15.4845L49.8992 16.0721C49.2167 20.5669 47.4603 24.3753 44.6634 27.3734L44.6759 27.3767Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_577_788">
          <rect
            width="56"
            height="74"
            fill="white"
            transform="translate(19.375) rotate(15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OnboardingIcon;
