import React from "react";

const ChevronUp = () => {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00003 5.83331L7.00003 5.83331C7.06078 5.83312 7.12032 5.81636 7.17225 5.78485C7.22419 5.75333 7.26654 5.70825 7.29476 5.65446C7.32298 5.60066 7.33599 5.54019 7.3324 5.47955C7.3288 5.41891 7.30874 5.36039 7.27436 5.31031L4.27436 0.976974C4.15003 0.797307 3.8507 0.797307 3.72603 0.976974L0.726028 5.31031C0.691303 5.36029 0.67094 5.41883 0.66715 5.47957C0.663361 5.54031 0.67629 5.60093 0.704533 5.65484C0.732777 5.70875 0.775254 5.75389 0.82735 5.78536C0.879446 5.81682 0.939169 5.8334 1.00003 5.83331Z"
        fill="#3D3D3D"
      />
    </svg>
  );
};

export default ChevronUp;
