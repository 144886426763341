import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Pagination,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllOrders } from "../../redux/slices/experiencesSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";

const rowsPerPageOptions = [15, 25, 30];

const OrdersPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allOrders, isLoading } = useSelector((state) => state.experiences);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredOrdersList, setFilteredOrdersList] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const totalRows = allOrders?.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  const startIndex = (page > 0 ? page - 1 : 0) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, totalRows);

  const displayedItems = allOrders?.slice(startIndex, endIndex);

  useEffect(() => {
    let trimmedValue = searchInput?.trimEnd().toString();

    let new_arr = displayedItems?.filter(
      (item) =>
        item?.uuid?.toString().toLowerCase().includes(trimmedValue) ||
        item?.total_amount?.toString().includes(trimmedValue)
    );
    setFilteredOrdersList(new_arr);
  }, [searchInput, allOrders, page]);

  useEffect(() => {
    dispatch(fetchAllOrders());
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Box
          sx={{
            padding: { xs: "10px 20px", sm: "10px 50px", lg: "10px 60px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              padding: "2px 10px",
              margin: { xs: "0px", md: "20px 0px 5px 0px" },
            }}
          >
            <Box
              onClick={() => navigate("/")}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ChevronLeft
                sx={{
                  fontSize: { xs: "22px", md: "28px" },
                  marginLeft: "-5px",
                  color: "#3D475C",
                }}
              />
            </Box>

            <Typography
              sx={{
                color: "#3D475C",
                fontWeight: "600",
                fontSize: { xs: "15px", md: "20px", lg: "24px" },
                fontFamily: "Fraunces",
              }}
            >
              Order List
            </Typography>
          </Box>
          <Box sx={{ padding: "10px" }}>
            <TextField
              placeholder="Search..."
              variant="standard"
              value={searchInput}
              onChange={(e) => handleSearchInputChange(e)}
              sx={{
                width: "100%",
                maxWidth: "330px",
                fontSize: "12px",
                fontFamily: "inherit",
                border: "1px solid #DEE2E6",
                borderRadius: "6px",
                "& .MuiInput-input": {
                  fontSize: "12px",
                  fontFamily: "inherit",
                  color: "#3D475C",
                  padding: "10px 10px",
                },
                "& .MuiInput-root:before": {
                  borderBottom: "0px !important",
                },
                "& .MuiInput-root:after": {
                  border: 0,
                },
                ".MuiFormHelperText-root.Mui-error": {
                  fontSize: "10px",
                },
              }}
            />
          </Box>
          {filteredOrdersList?.length > 0 ? (
            <>
              <TableContainer
                sx={{
                  scrollbarWidth: "none",
                  padding: "10px",
                  maxWidth: "95%",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <Table
                  sx={{
                    "& .MuiTableCell-root": {
                      border: 0,
                      fontFamily: "inherit",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow sx={{ border: 0 }}>
                      <TableCell
                        sx={{
                          color: "#9499A1",
                          fontSize: { xs: "9px", md: "11px" },
                          fontWeight: "600",
                          padding: "5px 5px",
                        }}
                      >
                        ITEM
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#9499A1",
                          fontSize: { xs: "9px", md: "11px" },
                          fontWeight: "600",
                          padding: "5px 5px",
                        }}
                      >
                        DATE
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#9499A1",
                          fontSize: { xs: "9px", md: "11px" },
                          fontWeight: "600",

                          padding: "5px 5px",
                        }}
                      >
                        TOTAL
                      </TableCell>
                      {/* <TableCell
                sx={{
                  color: "#9499A1",
                  fontSize: "9px",
                  fontWeight: "600",
                  minWidth: "20px",
                  padding: "5px 5px",
                }}
              >
                PAYMENT
              </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredOrdersList.map((item) => (
                      <TableRow
                        key={item.uuid}
                        onClick={() => navigate(`/order-details/${item.id}`)} // Handle row click
                        sx={{
                          borderBottom:
                            "1px dashed rgba(61, 61, 61, 0.50) !important",
                          cursor: "pointer",
                          height: { xs: "42px", md: "64px" },
                        }}
                      >
                        <TableCell
                          sx={{
                            color: "#3D475C",
                            fontSize: { xs: "10px", md: "14px" },
                            padding: "5px 5px",
                          }}
                        >
                          {item.uuid}
                        </TableCell>

                        <TableCell sx={{ padding: "5px 5px" }}>
                          <Typography
                            sx={{
                              color: "#3D475C",
                              fontSize: { xs: "11px", md: "14px" },
                              fontFamily: "inherit",
                              fontWeight: "600",
                            }}
                          >
                            {moment(item?.added_at).format("DD MMM, YYYY")}
                          </Typography>
                          <Typography
                            sx={{
                              color: "rgba(61, 61, 61, 0.60)",
                              fontSize: { xs: "10px", md: "12px" },
                              fontFamily: "inherit",
                            }}
                          >
                            {moment(item?.added_at).format("hh:mm a")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#3D475C",
                            fontSize: { xs: "12px", md: "14px" },
                            padding: "5px 5px",
                          }}
                        >
                          ${item?.total_amount}
                        </TableCell>
                        {/* <TableCell
                  sx={{
                    color: "rgba(61, 61, 61, 0.60)",
                    fontSize: "10px",
                    padding: "5px 5px",
                  }}
                >
                  Stripe
                </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  count={totalPages}
                  shape="rounded"
                  page={Number(page)}
                  onChange={handleChangePage}
                  hideNextButton
                  hidePrevButton
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: "rgba(61, 61, 61, 0.80)",
                      fontFamily: "inherit",
                    },
                    "& .Mui-selected": {
                      color: "#3D3D3D",
                      fontWeight: "600",
                      backgroundColor: "#F3F2EC",
                      borderRadius: "6px",
                    },
                  }}
                />
              </Box>
            </>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "85vh",
              }}
            >
              No available orders.
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default OrdersPage;
