import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
//Helmet Component
import HelmetPage from "../../components/helmetPage/HelmetPage";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Homepage1 = () => {
  const { winery } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [errorImage, setErrorImage] = useState(false);
  const onboarding_flow_checker = localStorage.getItem("onboarding_flow");

  useEffect(() => {
    const timer = setTimeout(() => {
      if (onboarding_flow_checker === "true") {
        navigate("/");
      } else {
        navigate("/homepage-content");
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <HelmetPage pageTitle={"Home Page"} />
      <Box
        sx={{
          minHeight: "100vh",
          backgroundImage: `url(${require("../../assets/images/homepage1-background.jpg")})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "150px", sm: "200px" },
            maxHeight: { xs: "100px", sm: "200px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!errorImage ? (
            <>
              <img
                src={winery?.logo}
                width="100%"
                alt=""
                height={"auto"}
                onError={() => setErrorImage(true)}
                style={{ color: "white", fontSize: "25px" }}
              />
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontSize: "25px",
                  color: "white",
                }}
              >
                {winery?.company_name}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Homepage1;
