import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TimelineItem from "./TimelineItem";
import { useNavigate } from "react-router-dom";

const TimelineRowItem = ({ rowContent }) => {
  const navigate = useNavigate();
  const [timelineArray, setTimelineArray] = useState([]);
  // Convert the object to an array of key-value pairs
  useEffect(() => {
    if (rowContent) {
      const postsArray = Object.entries(rowContent);

      // Use map to create a new array with the desired structure
      const transformedArray = postsArray?.map(([month, posts]) => ({
        month,
        posts,
      }));
      setTimelineArray(transformedArray);
    }
  }, [rowContent]);

  return (
    <>
      {timelineArray?.map((row, index) => {
        return (
          <Grid container key={index}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
                marginTop: "2px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#3D3D3D",
                    fontSize: "13px",
                    fontFamily: "inherit",
                    fontWeight: "600",
                  }}
                >
                  {row?.month}
                </Typography>
              </Box>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`/timeline-month-details/${row?.month}`)
                }
              >
                <Typography
                  sx={{
                    color: "rgba(61, 61, 61, 0.70)",
                    fontSize: "12px",
                    fontWeight: "600",
                    fontFamily: "inherit",
                    opacity: 0.7,
                  }}
                >
                  See all
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginTop: "5px",
                overflowX: "scroll",
                scrollbarWidth: "none", // For Firefox
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {row.posts?.map((post, index1) => {
                return (
                  <Box key={index1}>
                    <TimelineItem post={post} />
                  </Box>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default TimelineRowItem;
