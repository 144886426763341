import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, List, ListSubheader, Typography } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";

//Redux / Api
import { useDispatch, useSelector } from "react-redux";

// Header filter component
import HeaderFilter from "../components/layout/HeaderFilter";
import HomepageItem from "../components/homepage/HomepageItem";
import {
  fetchHomeContent,
  fetchUpcomingPhases,
} from "../redux/slices/homeSlice";
import TimelineIcon from "../assets/icons/headerIcons/TimelineIcon";
import UpcomingPhases from "../components/homepage/UpcomingPhases";
import ExperiencesIcons from "../assets/icons/headerIcons/ExperiencesIcons";
import { fetchAllExperiences } from "../redux/slices/experiencesSlice";
import ExperiencesHomeItem from "../components/experiences/ExperiencesHomeItem";
// import LoadingSpinner from "../components/loadingSpinner/LoadingSpinner";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listRef = useRef(null);

  const { winery, access_token } = useSelector((state) => state.auth);
  const { homeContent, upcomingPhases } = useSelector((state) => state.home);
  const { allExperiences } = useSelector((state) => state.experiences);
  const onboarding_flow_checker = localStorage.getItem("onboarding_flow");
  useEffect(() => {
    if (winery?.hide_home) {
      navigate("/experiences");
    }
  }, [winery]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      const handleScroll = () => {
        localStorage.setItem("scrollPosition", window.scrollY.toString());
      };

      const storedScrollPosition = localStorage.getItem("scrollPosition");
      if (storedScrollPosition) {
        window.scrollTo(0, parseInt(storedScrollPosition, 10));
      }

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      const handleScroll = () => {
        if (listRef.current) {
          localStorage.setItem(
            "scrollPosition",
            listRef.current.scrollTop.toString()
          );
        }
      };

      const storedScrollPosition = localStorage.getItem("scrollPosition");

      if (storedScrollPosition && listRef.current) {
        listRef.current.scrollTop = parseInt(storedScrollPosition, 10);
      }

      if (listRef.current) {
        listRef.current.addEventListener("scroll", handleScroll);
      }

      return () => {
        if (listRef.current) {
          listRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [listRef]);

  useEffect(() => {
    if (
      (!onboarding_flow_checker && winery?.is_public === true) ||
      (!onboarding_flow_checker && winery?.is_public === false && access_token)
    ) {
      navigate("/homepage1");
    }
  }, [onboarding_flow_checker, winery, access_token]);

  useEffect(() => {
    dispatch(fetchHomeContent());
    dispatch(fetchUpcomingPhases());
    dispatch(fetchAllExperiences());
  }, []);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          position: "relative",
          padding: { xs: 0, sm: "0px 30px", lg: "0px 60px" },
        }}
      >
        <Grid
          item
          md={9}
          sx={{
            display: { xs: "none", sm: "flex" },
            marginTop: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "30px",
              color: "#3D3D3D",
              fontWeight: "600",
              fontFamily: "Fraunces",
            }}
          >
            Home
          </Typography>
        </Grid>
        <HeaderFilter />
        <List
          ref={listRef}
          sx={{
            mb: 0,
            pb: 0,
            pt: 0,
            overflowX: { md: "scroll" },
            height: {
              md: access_token
                ? "calc(100vh - 100px)"
                : "calc(100vh - 100px - 80px)",
            },
          }}
        >
          {Object.entries(homeContent).map(([month, postsInMonth]) => (
            <Box key={month}>
              <ListSubheader
                sx={{
                  bgcolor: "background.paper",
                  padding: 0,
                  fontFamily: "inherit",
                }}
              >
                <Box
                  sx={{
                    height: "35px",
                    width: "100%",
                    backgroundColor: "rgba(245, 245, 245, 0.50)",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "inherit",
                      fontSize: "15px",
                      fontWeight: "600",
                      color: "#3D3D3D",
                      padding: "0px 10px",
                      opacity: 0.8,
                    }}
                  >
                    {month}
                  </Typography>
                </Box>
              </ListSubheader>

              {postsInMonth?.map((post, index) => (
                <Box
                  key={index}
                  sx={{ padding: { xs: "15px 20px", sm: "0px" } }}
                >
                  <HomepageItem
                    item={post}
                    isDesktop={window.innerWidth < 768 ? false : true}
                  />
                </Box>
              ))}
            </Box>
          ))}
        </List>
      </Grid>
      <Grid
        item
        md={4}
        sx={{
          display: { xs: "none", md: "block" },
          marginTop: "55px",
          overflowX: { md: "scroll" },
          height: {
            md: access_token
              ? "calc(100vh - 55px)"
              : "calc(100vh - 55px - 80px)",
          },
        }}
      >
        <Grid
          container
          sx={{ flexDirection: "row", display: "flex", alignItems: "center" }}
        >
          <TimelineIcon
            isMenu
            isDesktopActive
            color={"rgba(61, 61, 61, 0.80)"}
            width={"23"}
            height={"23"}
          />
          <Typography
            sx={{
              fontSize: "21px",
              color: "rgba(61, 61, 61, 0.80)",
              marginLeft: "5px",
              fontFamily: "Fraunces",
              fontWeight: "400",
              letterSpacing: 0,
              marginLeft: "10px",
            }}
          >
            Happening this month
          </Typography>
        </Grid>
        <Grid sx={{ padding: "10px 5px 0px 0px" }}>
          {upcomingPhases?.map((phase, index) => {
            return (
              <Box key={index}>
                <UpcomingPhases
                  name={phase?.title}
                  textColor={phase?.text_color}
                  backgroundColor={phase?.color}
                  slug={phase?.slug}
                />
              </Box>
            );
          })}
        </Grid>
        <Grid
          container
          sx={{
            flexDirection: "row",
            margin: "25px 0px 15px 0px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ExperiencesIcons
            isMenu
            isDesktopActive
            color={"rgba(61, 61, 61, 0.80)"}
            width={"23"}
            height={"23"}
          />
          <Typography
            sx={{
              fontSize: "21px",
              color: "rgba(61, 61, 61, 0.80)",
              marginLeft: "5px",
              fontFamily: "Fraunces",
              fontWeight: "400",
              letterSpacing: 0,
              marginLeft: "10px",
            }}
          >
            Experiences
          </Typography>
        </Grid>
        <Grid sx={{ padding: "0px 10px 0px 0px" }}>
          {allExperiences?.map((experience, index) => {
            return (
              <Box key={index}>
                <ExperiencesHomeItem
                  experience={experience}
                  isHomePage
                  isDesktop
                />
              </Box>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default Home;
