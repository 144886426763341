import React from "react";
import { Box, Container, Typography } from "@mui/material";
import HeaderLogo from "../components/headerLogo/HeaderLogo";
import { useSelector } from "react-redux";
import ErrorIcon from "../assets/icons/ErrorIcon";
import HelmetPage from "../components/helmetPage/HelmetPage";

const ComingSoon = () => {
  const { winery } = useSelector((state) => state.auth);
  return (
    <Container className="main-container">
      <HelmetPage pageTitle={"Coming Soon"} />
      <HeaderLogo winery={winery} />

      <Box className="form-container">
        <Box className="header-icon">
          <ErrorIcon />
        </Box>
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "600",
            fontFamily: "inherit",
            textAlign: "center",
          }}
        >
          Winery Coming Soon
        </Typography>
      </Box>
    </Container>
  );
};

export default ComingSoon;
