import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Divider, List, ListSubheader, Typography } from "@mui/material";
//Component
import HomepageItem from "../../components/homepage/HomepageItem";
//Redux/Api
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectedPhaseTimelineContent } from "../../redux/slices/timelineSlice";
//Loading
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
//icons
import { ChevronLeft } from "@mui/icons-material";
import MobileHeader from "../../components/layout/MobileHeader";
import BottomSignInPrompt from "../../components/layout/BottomSignInPrompt";

const TimelineMonthDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.auth);
  const { selectedPhase, selectedPhaseMonthTimelineContent, isLoading } =
    useSelector((state) => state.timeline);
  const { id } = useParams();

  useEffect(() => {
    let obj = {
      slug: selectedPhase,
      month: id,
    };
    dispatch(fetchSelectedPhaseTimelineContent(obj));
  }, [id]);

  return (
    <Box sx={{ paddingBottom: !access_token && "150px" }}>
      <MobileHeader showNavigation={false} />
      <Box
        onClick={() => navigate(-1)}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          cursor: "pointer",
          padding: "2px 10px",
          color: "#3D3D3D",
        }}
      >
        <ChevronLeft
          sx={{ fontSize: "32px", marginLeft: "-5px", color: "#3D3D3D" }}
        />
      </Box>
      {isLoading ? (
        <>
          <LoadingSpinner />
        </>
      ) : (
        <>
          {selectedPhaseMonthTimelineContent?.length > 0 && (
            <List sx={{ m: 0, p: 0 }}>
              <Box>
                <ListSubheader
                  sx={{
                    bgcolor: "background.paper",
                    padding: 0,
                    fontFamily: "inherit",
                  }}
                >
                  <Box
                    sx={{
                      height: "35px",
                      width: "100%",
                      backgroundColor: "#F5F5F5",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "inherit",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#3D3D3D",
                        padding: "0px 20px",
                      }}
                    >
                      {selectedPhaseMonthTimelineContent[0]?.month}
                    </Typography>
                  </Box>
                </ListSubheader>
                <Box sx={{ padding: "10px 20px" }}>
                  {selectedPhaseMonthTimelineContent?.map((item, index) => {
                    return (
                      <Box key={index} sx={{ marginBottom: "5px" }}>
                        <HomepageItem item={item} />
                        <Divider
                          sx={{
                            backgroundColor: "rgba(61, 61, 61, 0)",
                            width: "100%",
                            margin: "15px 0px",
                          }}
                        />
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </List>
          )}
        </>
      )}
      {!access_token && <BottomSignInPrompt />}
    </Box>
  );
};

export default TimelineMonthDetails;
