import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//CSS
import "./auth.css";

//Icons
import SignInIcon from "../../assets/icons/SignInIcon";
//MUI components
import { Box, Container, Grid, Button } from "@mui/material";
//Validation and Formik
import * as yup from "yup";
import { Form, Formik } from "formik";
//Components
import AuthInputField from "../../components/inputs/AuthInputFIeld";

//Redux/ Api
import { resetPassword } from "../../config/api";
import { useDispatch, useSelector } from "react-redux";
import { feedbackContentPage } from "../../redux/slices/authSlice";
//Header Logo
import HeaderLogo from "../../components/headerLogo/HeaderLogo";
//Helmet Component
import HelmetPage from "../../components/helmetPage/HelmetPage";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { winery } = useSelector((state) => state.auth);

  const [token, setToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    let queryToken = queryParams.get("token");
    setToken(queryToken);
  }, []);

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(8, "At least 8 characters")
      .matches(/[a-z]/, "At least one lowercase char")
      .matches(/[A-Z]/, "At least one uppercase char")
      .matches(/[0-9]/, "At least one numeric char")
      .matches(/[!@#$%^&*-]/, "At least one special character (@,!,#, etc).")
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Password is required"),
  });
  const handleSubmit = async (values) => {
    let obj = {
      token: token,
      password: values.password,
    };

    await resetPassword(obj).then((res) => {
      if (res.status === 200) {
        navigate("/feedback");
        dispatch(
          feedbackContentPage({
            type: "reset_password",
            title: "Reset Password",
            message: res?.data?.success?.message,
          })
        );
      } else {
        setErrorMessage("Something went wrong, please try again later.");
      }
    });
  };

  return (
    <Container className="main-container">
      <HelmetPage pageTitle={"Reset Password"} />
      <HeaderLogo winery={winery} />

      <Box className="form-container">
        <Box className="header-icon">
          <SignInIcon />
        </Box>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ values, handleChange, handleBlur, touched, errors, isValid }) => (
            <Form>
              <Grid container className="grid-container">
                <div className="form-title">Reset Password</div>

                <Grid item xs={12}>
                  <AuthInputField
                    name="password"
                    placeholder="Password"
                    type="password"
                    variant="outlined"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password ? errors.password : ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AuthInputField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    variant="outlined"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.confirmPassword && Boolean(errors.confirmPassword)
                    }
                    helperText={
                      touched.confirmPassword ? errors.confirmPassword : ""
                    }
                  />
                </Grid>
                {errorMessage && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      textAlign: "left",
                      width: "100%",
                      color: "#d32f2f",
                      fontSize: "15px",
                      maxWidth: { xs: "250px", sm: "310px", md: "400px" },
                    }}
                  >
                    <Box>{errorMessage}</Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!isValid}
                    sx={{
                      backgroundColor: "#3D3D3D",
                      borderRadius: "10px",
                      marginTop: "10px",
                      textTransform: "none",
                      height: { xs: "40px", sm: "45px" },
                      width: { xs: "250px", sm: "310px", md: "350px" },
                      fontSize: { xs: "16px", sm: "20px" },
                      fontFamily: "inherit",
                      "&:hover": {
                        backgroundColor: "#c88080ff",
                      },
                    }}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <Box
                    sx={{
                      marginTop: "10px",
                      fontSize: { xs: "11px", sm: "18px" },
                      color: "rgba(0, 0, 0, 0.60)",
                    }}
                  >
                    Already have an account?
                  </Box>
                  <Box
                    onClick={() => navigate(`/login`)}
                    sx={{
                      color: "#3D3D3D",
                      fontSize: { xs: "12px", sm: "19px" },
                      fontWeight: "600",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                  >
                    Sign in
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  );
};

export default ResetPassword;
