import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = () => {
  const { winery, access_token } = useSelector((state) => state.auth);

  if (!access_token && !winery?.is_public) {
    return <Navigate to="/login" />;
  }

  if (winery?.is_public || (!winery?.is_public && access_token)) {
    return (
      <div>
        <Outlet />
      </div>
    );
  }

  return <Navigate to="/login" />;
};
export default PrivateRoute;

//
// Documentation:
// As for this Private Route we have multiple Cases based on our "access_token" and our "winery?.is_public" (From the winery):
// First if the "winery?.is_public" is true => the User will be able to browse our software but cannot access all the features
//      based on the permissions to be authenticated.
// Second if the "winery?.is_public" is false => we need to restrict the User where he should not be able to access the private routes
//      unless he's authenticated and we should give him access only to the authentication routes.
// Third if the "winery?.is_public" is false and the "access_token" is valid => The User will be able to browse all the routes
//
